import { createAction, props } from '@ngrx/store';
import { AuthenticateTokenModel, MarketingUrlOptionDto, NewPatientUrlOptionDto, TokenDto, PatientCheckInOptionUrlDto, PatientSelfScheduleOptionUrlDto, PatientRescheduleOptionUrlDto, PatientContractPlanDto } from 'src/app/shared/services/api.service';
import { UpdateStr } from '@ngrx/entity/src/models';
import { AppointmentDetail } from '../../shared/models/appointment/AppointmentDetail';
import { MarketingDetail } from 'src/app/shared/models/appointment/MarketingDetails';

export const AuthenticatorInit = createAction('[Auth] Authenticator Initialize');

//LOGIN
export const AuthenticateRequest = createAction('[Auth] Authenticate Request', props<{ authModel: AuthenticateTokenModel; maskedTenantKey: string }>());
export const AuthenticateSuccess = createAction('[Auth] Authenticate Success', props<{ userCredentials: TokenDto; maskedTenantKey: string }>());
export const AuthenticateFailure = createAction('[Auth] Authenticate Failure', props<{ error: any }>());

export const AuthenticatePatientPortalBypassSuccess = createAction('[Auth] Authenticate PatientPortal Bypass Request', props<{ userCredentials: TokenDto; maskedTenantKey: string }>());

export const AuthenticatePatientPortalRequest = createAction('[Auth] Authenticate PatientPortal Request', props<{ userCredentials: TokenDto; maskedTenantKey: string }>());
export const AuthenticatePatientPortalSuccess = createAction('[Auth] Authenticate PatientPortal Success', props<{ userCredentials: TokenDto; maskedTenantKey: string }>());
export const AuthenticatePatientPortalFailure = createAction('[Auth] Authenticate PatientPortal Failure', props<{ error: any }>());

export const LoginRequest = createAction('[Auth] Login Request', props<{ authModel: AuthenticateTokenModel; }>());
export const LoginSuccess = createAction('[Auth] Login Success', props<{ userCredentials: TokenDto; }>());
export const LoginFailure = createAction('[Auth] Login Failure', props<{ error: any }>());

export const SetTenant = createAction('[Auth] Set Tenant', props<{ tenantName: string; tenantKey: string }>());
export const SetAppointmentDetail = createAction('[Auth] Set AppointmentDetail', props<{ appointmentDetail: AppointmentDetail }>());
export const SetMarketingDetail = createAction('[Auth] Set MarketingDetail', props<{ marketingDetail: MarketingDetail }>());

export const AddTokens = createAction('[Auth] Add Tokens', props<{ tokens: TokenDto[] }>());
export const UpdateToken = createAction('[Auth] Update Token', props<UpdateStr<TokenDto>>());

//TOKEN REFRESH
export const RefreshRequest = createAction('[Auth] Refresh Request', props<{ id: string; accessToken: string, refreshToken: string }>());
export const RefreshSuccess = createAction('[Auth] Refresh Success', props<{ id: string; userCredentials: Partial<TokenDto> }>());
export const RefreshFailure = createAction('[Auth] Refresh Failure', props<{ id: string; error: any }>());

export const NewPatientOptionRequest = createAction('[Auth] NewPatientOption Request', props<{ maskedTenantKey: string }>());
export const NewPatientOptionSuccess = createAction('[Auth] NewPatientOption Success', props<{ newPatientUrlOption: NewPatientUrlOptionDto }>());
export const NewPatientOptionFailure = createAction('[Auth] NewPatientOption Failure', props<{ error: any }>());

export const MarketingOptionRequest = createAction('[Auth] MarketingOption Request', props<{ maskedTenantKey: string }>());
export const MarketingOptionSuccess = createAction('[Auth] MarketingOption Success', props<{ marketingUrlOption: MarketingUrlOptionDto }>());
export const MarketingOptionFailure = createAction('[Auth] MarketingOption Failure', props<{ error: any }>());

export const PatientCheckInOptionRequest = createAction('[Auth] PatientCheckInOption Request', props<{ maskedTenantKey: string }>());
export const PatientCheckInOptionSuccess = createAction('[Auth] PatientCheckInOption Success', props<{ patientCheckInOption: PatientCheckInOptionUrlDto }>());
export const PatientCheckInOptionFailure = createAction('[Auth] PatientCheckInOption Failure', props<{ error: any }>());

export const PatientRescheduleOptionRequest = createAction('[Auth] PatientRescheduleOption Request', props<{ maskedTenantKey: string }>());
export const PatientRescheduleOptionSuccess = createAction('[Auth] PatientRescheduleOption Success', props<{ patientRescheduleOption: PatientRescheduleOptionUrlDto }>());
export const PatientRescheduleOptionFailure = createAction('[Auth] PatientRescheduleOption Failure', props<{ error: any }>());

export const PatientSelfScheduleOptionRequest = createAction('[Auth] PatientSelfScheduleOption Request', props<{ maskedTenantKey: string }>());
export const PatientSelfScheduleOptionSuccess = createAction('[Auth] PatientSelfScheduleOption Success', props<{ patientSelfScheduleOption: PatientSelfScheduleOptionUrlDto }>());
export const PatientSelfScheduleOptionFailure = createAction('[Auth] PatientSelfScheduleOption Failure', props<{ error: any }>());

export const PatientContractPlanRequest = createAction('[Auth] PatientContractPlan Request', props<{ maskedTenantKey: string }>());
export const PatientContractPlanSuccess = createAction('[Auth] PatientContractPlan Success', props<{ patientContractPlan: PatientContractPlanDto }>());
export const PatientContractPlanFailure = createAction('[Auth] PatientContractPlan Failure', props<{ error: any }>());
