import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthStoreModule } from './auth-store';
import { LocationStoreModule } from './location-store';
import { InsuranceCompanyStoreModule } from './insurance-company-store';
import { AppointmentStoreModule } from './appointment-store';
import { SignalRHubStoreModule } from './signalr-hub-store';

@NgModule({
    declarations: [],
    imports: [
        // The load order of these do matter (for now), please do not sort the module imports
        // For now, if your store depends on the intialization action of another then place it above said dependency.
        // If you don't know where to put it then place it anywhere above AuthStoreModule
        CommonModule,
        LocationStoreModule,
        InsuranceCompanyStoreModule,
        AppointmentStoreModule,
        SignalRHubStoreModule,
        AuthStoreModule, // For now this needs to be listed last. See note above
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
    ]
})
export class RootStoreModule { }
