<div class="main-container">
    <div class="right-panel container">
        <div class="row d-none d-sm-flex">
            <div class="col">
                <div class="logo">Logo</div>
            </div>
            <div class="col"
                 style="text-align: center;">
                <div class="office-name">Smiles Shop</div>
            </div>
            <div class="col" style="text-align: end;">
                <div class="office-addr">
                    <div>1150 Main Street</div>
                    <div>Vancouver, WA</div>
                    <div>360-606-4385</div>
                </div>
            </div>
        </div>
        <div class="row d-block d-sm-none">
            <div class="col sm-header">
                <div class="logo">Logo</div>
            </div>
        </div>
        <div class="row d-block d-sm-none"
             style="padding-bottom: 20px;">
            <div class="col sm-header">
                <div class="office-name">Smiles Shop</div>
            </div>
        </div>
        <div class="row d-block d-sm-none">
            <div class="col sm-header">
                <div class="office-addr"
                     style="text-align: center;">
                    <div>1150 Main Street</div>
                    <div>Vancouver, WA</div>
                    <div>360-606-4385</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="patient-row">Treatment Options for {{patient?.firstName}} {{patient?.lastName}}</div>
            </div>
        </div>
        <div class="d-block d-sm-none">
            <div class="row">
                <div class="col-12">
                    <mat-form-field style="width: 100%">
                        <mat-label>Treatment</mat-label>
                        <mat-select [(value)]="selectedTreatmentPlan"
                                    (selectionChange)="treatmentChange($event.value)">
                            <mat-option [value]="treatment"
                                        *ngFor="let treatment of selectedTreatmentPlans">
                                {{treatment.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <ng-container *ngIf="selectedTreatmentPlan">
                <div class="row"
                     style="margin-bottom: 10px;">
                    <div class="col-12">
                        <div class="info-panel">
                            <div class="info-title">Your orthodontic investment includes:</div>
                            <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>All xrays taken in our office</div>
                            <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>One set of removable and/or fixed retainer(s)</div>
                            <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>Communication with your dentist throughout treatment</div>
                            <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>Office visits and adjustment during treatment</div>
                            <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>Office will file insurance claim(s) for you.</div>
                        </div>
                    </div>
                </div>
                <div class="row"
                     style="border-bottom: 1px solid #A5A5A5; padding-bottom: 2px;">
                    <div class="col-12">
                        <div class="selected-plan-name">
                            {{selectedTreatmentPlan.name}}
                        </div>
                        <div class="selected-plan-estimated-time">
                            estimated time: {{selectedTreatmentPlan.treatmentLengthMonths}}m
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="plan-element-label">Records fee</div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value">
                            {{selectedTreatmentPlan.recordsFee | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="plan-element-label">Treatment fee</div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value">
                            {{selectedTreatmentPlan.treatmentFee | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="plan-element-label-header">
                            Additional options
                        </div>
                    </div>
                    <div class="col-7">
                    </div>
                </div>
                <div class="row"
                     *ngFor="let feeItem of selectedTreatmentPlan.fees">
                    <div class="col-5">
                        <div class="other-options">
                            {{feeItem.name}}
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value">
                            {{feeItem.amount | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row total-fee-row">
                    <div class="col-5">
                        <div class="plan-element-label"
                             style="font-weight: bold;">
                            Total Fee
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value"
                             style="font-weight: bold;">
                            {{selectedTreatmentPlan.total | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="plan-element-label-header">
                            Adjustments
                        </div>
                    </div>
                    <div class="col-7">
                    </div>
                </div>
                <div class="row"
                     *ngFor="let discount of selectedTreatmentPlan.discounts">
                    <div class="col-5">
                        <div class="other-options">
                            {{discount.name}}
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{discount.amount | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="plan-element-label-header">
                            Insurance estimates
                        </div>
                    </div>
                    <div class="col-7">
                    </div>
                </div>
                <div class="row"
                     *ngFor="let insurance of selectedTreatmentPlan.insurances">
                    <div class="col-5">
                        <div class="other-options">
                            {{insurance.name}}
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{insurance.amount | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row"
                     style="border-top: 1px solid #A5A5A5;">
                    <div class="col-5">
                        <div class="plan-element-label"
                             style="font-weight: bold; color: #0ED251; border-right: transparent;">
                            Your portion:
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="plan-element-value"
                             style="font-weight: bold; color: #0ED251; font-size: 16px;">
                            {{selectedTreatmentPlan.patientPortion | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="customize-plan-heading">Customize Your Payment Plan</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'cash'}"
                             (click)="selectCustomizePlanTab('cash')">
                            <div>Pay in Full-cash</div>
                            <div>(5% discount)</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'cc'}"
                             (click)="selectCustomizePlanTab('cc')">
                            <div>Pay in full-credit card</div>
                            <div>(2% discount)</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'officeFinance'}"
                             (click)="selectCustomizePlanTab('officeFinance')">
                            <div>Interest Free</div>
                            <div>In-Office Financing</div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="selectedCustomizePlanTab == 'cash' && selectedTreatmentPlan">
                    <div class="row">
                        <div class="col-12">
                            <div class="cash-progress-cont">
                                <div class="cash-progress">{{selectedTreatmentPlan.patientPortion | number: '0.2-2'}} minus 5% discount</div>
                                <div class="patient-portion-cont">
                                    <div class="patient-portion">patient portion</div>
                                    <div class="patient-portion-amount">{{cashPatientPortion(5) | number: '0.2-2'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="selectedCustomizePlanTab == 'cc' && selectedTreatmentPlan">
                    <div class="row" style="padding-top: 60px;">
                        <div class="col-12">
                            <div class="cash-progress">{{selectedTreatmentPlan.patientPortion | number: '0.2-2'}} minus 2% discount</div>
                            <div class="patient-portion-cont">
                                <div class="patient-portion-amount" style="color: #000000;">due now</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">patient portion</div>
                                <div class="all-element-value">{{selectedTreatmentPlan.patientPortion | number: '0.2-2'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">&nbsp;</div>
                                <div class="all-element-value">&mdash;</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">paid in full discount</div>
                                <div class="all-element-value">{{paidInFullDiscount(2) | number: '0.2-2'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">&nbsp;</div>
                                <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">insurance estimate*</div>
                                <div class="all-element-value">1500.00</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="col-12" style="text-align: center">
                                <div class="all-element">
                                    <div class="all-element-label">total contract</div>
                                    <div class="all-element-value" style="color: #0ED251; font-size: 20px;">{{cashPatientPortion(2, true) | number: '0.2-2'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="insurance-info">*Insurance is just an estimate. You are responsible for any amounts insurance does not pay</div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="selectedCustomizePlanTab == 'officeFinance' && selectedTreatmentPlan">
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12">
                            <div class="progress-value">{{selectedTreatmentPlan.customAmountDown | number: '0.2-2'}}</div>

                            <div class="range">
                                <input type="range" 
                                       min="1" 
                                       [max]="selectedTreatmentPlan.patientPortion" 
                                       step="0.1"
                                       [value]="selectedTreatmentPlan.customAmountDown"
                                       (input)="changeCustomAmountDown($event.target.value)" />
                            </div>

                            <div class="office-finance-patient-portion">down payment</div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12">
                            <div class="progress-value">{{selectedTreatmentPlan.customPaymentAmountMonthly | number: '0.2-2'}}</div>
                            <div class="range">
                                <input type="range"
                                       min="1"
                                       [max]=selectedTreatmentPlan.treatmentLengthMonths+12
                                       step="1"
                                       [value]="selectedTreatmentPlan.customTreatmentLengthMonths"
                                       (input)="changeCustomTreamentLengh($event.target.value)" />
                            </div>
                            <div class="office-finance-patient-portion">
                                number of months <span style="font-weight: bold;">{{selectedTreatmentPlan.customTreatmentLengthMonths}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">patient portion</div>
                                <div class="all-element-value">{{selectedTreatmentPlan.patientPortion}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">&nbsp;</div>
                                <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align:center">
                            <div class="all-element">
                                <div class="all-element-label">insurance estimate*</div>
                                <div class="all-element-value">1500.00</div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="selectedTreatmentPlan.customTreatmentLengthMonths > 18">
                        <div class="row">
                            <div class="col-12" style="text-align:center">
                                <div class="all-element">
                                    <div class="all-element-label">&nbsp;</div>
                                    <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                                </div>
                            </div>
                        </div>

                        <div class="row" style="padding-top: 20px;">
                            <div class="col-12" style="text-align:center">
                                <div class="all-element">
                                    <div class="all-element-label">management fee</div>
                                    <div class="all-element-value" style="color: #000000;">350.00</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row" style="padding-top: 20px;">
                        <div class="col-12" style="text-align: center">
                            <div class="all-element">
                                <div class="all-element-label">total contract</div>
                                <div class="all-element-value" style="color: #0ED251; font-size: 20px;">{{interestFreeCalculation() | number: '0.2-2'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="insurance-info">*Insurance is just an estimate. You are responsible for any amounts insurance does not pay</div>
                        </div>
                    </div>
                </ng-container>

                <div class="row" style="padding-top: 20px;">
                    <div class="col-6">
                        <div class="not-now-btn">Not now</div>
                    </div>

                    <div class="col-6" style="text-align: right">
                        <button mat-flat-button style="background: #3F91CD; color: white;">
                            Sign and Pay Now
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="d-none d-sm-block">
            <div class="row"
                 style="margin-bottom: 10px;">
                <div class="col-2">

                </div>
                <div class="col-5">
                    <div class="info-panel">
                        <div class="info-title">Your orthodontic investment includes:</div>
                        <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>All xrays taken in our office</div>
                        <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>One set of removable and/or fixed retainer(s)</div>
                        <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>Communication with your dentist throughout treatment</div>
                        <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>Office visits and adjustment during treatment</div>
                        <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>Office will file insurance claim(s) for you.</div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="info-panel">
                        <div class="info-title">Your orthodontic investment includes:</div>
                        <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>All xrays taken in our office</div>
                        <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>One set of removable and/or fixed retainer(s)</div>
                        <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>Communication with your dentist throughout treatment</div>
                        <div class="info-txt"><mat-icon style="font-size: 16px; margin-right: 3px;">check</mat-icon>Office visits and adjustment during treatment</div>
                    </div>
                </div>
            </div>
            <div class="row"
                 style="border-bottom: 1px solid #A5A5A5; padding-bottom: 2px;">
                <div class="col-2">

                </div>
                <div class="col-5">
                    <div class="selected-plan-name"
                         *ngIf="selectedTreatmentPlans[0]">
                        {{selectedTreatmentPlans[0].name}}
                    </div>
                    <div class="selected-plan-estimated-time"
                         *ngIf="selectedTreatmentPlans[0]">
                        estimated time: {{selectedTreatmentPlans[0].treatmentLengthMonths}}m
                    </div>
                </div>
                <div class="col-5">
                    <div class="selected-plan-name"
                         *ngIf="selectedTreatmentPlans[1]">
                        {{selectedTreatmentPlans[1].name}}
                    </div>
                    <div class="selected-plan-estimated-time"
                         *ngIf="selectedTreatmentPlans[1]">
                        estimated time: {{selectedTreatmentPlans[1].treatmentLengthMonths}}m
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="plan-element-label">Records fee</div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[0]">
                        {{selectedTreatmentPlans[0].recordsFee | number: '0.2-2'}}
                    </div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[0]">
                        {{selectedTreatmentPlans[1].recordsFee | number: '0.2-2'}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="plan-element-label">Treatment fee</div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[0]">
                        {{selectedTreatmentPlans[0].treatmentFee | number: '0.2-2'}}
                    </div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[1]">
                        {{selectedTreatmentPlans[1].treatmentFee | number: '0.2-2'}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="plan-element-label-header">
                        Additional options
                    </div>
                </div>
                <div class="col-5">
                </div>
                <div class="col-5">
                </div>
            </div>
            <ng-container *ngIf="selectedTreatmentPlans[0]">
                <div class="row"
                     *ngFor="let feeItem of selectedTreatmentPlans[0].fees">
                    <div class="col-2">
                        <div class="other-options">
                            {{feeItem.name}}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value">
                            {{feeItem.amount | number: '0.2-2'}}
                        </div>
                    </div>
                    <div class="col-5">
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedTreatmentPlans[1]">
                <div class="row"
                     *ngFor="let feeItem of selectedTreatmentPlans[1].fees">
                    <div class="col-2">
                        <div class="other-options">
                            {{feeItem.name}}
                        </div>
                    </div>
                    <div class="col-5">

                    </div>
                    <div class="col-5">
                        <div class="plan-element-value">
                            {{feeItem.amount | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row total-fee-row">
                <div class="col-2">
                    <div class="plan-element-label"
                         style="font-weight: bold;">
                        Total Fee
                    </div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[0]"
                         style="font-weight: bold;">
                        {{selectedTreatmentPlans[0].total | number: '0.2-2'}}
                    </div>
                </div>
                <div class="col-5">
                    <div class="plan-element-value"
                         *ngIf="selectedTreatmentPlans[1]"
                         style="font-weight: bold;">
                        {{selectedTreatmentPlans[1].total | number: '0.2-2'}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="plan-element-label-header">
                        Adjustments
                    </div>
                </div>
                <div class="col-5">
                </div>
                <div class="col-5">
                </div>
            </div>
            <ng-container *ngIf="selectedTreatmentPlans[0]">
                <div class="row"
                     *ngFor="let discount of selectedTreatmentPlans[0].discounts">
                    <div class="col-2">
                        <div class="other-options">
                            {{discount.name}}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{discount.amount | number: '0.2-2'}}
                        </div>
                    </div>
                    <div class="col-5">
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedTreatmentPlans[1]">
                <div class="row"
                     *ngFor="let discount of selectedTreatmentPlans[1].discounts">
                    <div class="col-2">
                        <div class="other-options">
                            {{discount.name}}
                        </div>
                    </div>
                    <div class="col-5">
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{discount.amount | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col-2">
                    <div class="plan-element-label-header">
                        Insurance estimates
                    </div>
                </div>
                <div class="col-5">
                </div>
                <div class="col-5">
                </div>
            </div>
            <ng-container *ngIf="selectedTreatmentPlans[0]">
                <div class="row"
                     *ngFor="let insurance of selectedTreatmentPlans[0].insurances">
                    <div class="col-2">
                        <div class="other-options">
                            {{insurance.name}}
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{insurance.amount | number: '0.2-2'}}
                        </div>
                    </div>
                    <div class="col-5">
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedTreatmentPlans[1]">
                <div class="row"
                     *ngFor="let insurance of selectedTreatmentPlans[1].insurances">
                    <div class="col-2">
                        <div class="other-options">
                            {{insurance.name}}
                        </div>
                    </div>
                    <div class="col-5">
                    </div>
                    <div class="col-5">
                        <div class="plan-element-value"
                             style="color: #FD7474;">
                            {{insurance.amount | number: '0.2-2'}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row" style="border-top: 1px solid #A5A5A5;">
                <div class="col-2">
                    <div class="plan-element-label"
                         style="font-weight: bold; color: #0ED251; border-right: transparent;">
                        Your portion:
                    </div>
                </div>
                <div class="col-5">
                    <ng-container *ngIf="selectedTreatmentPlans[0]">
                        <div class="plan-element-value"
                             style="font-weight: bold; color: #0ED251; font-size: 16px;">
                            {{selectedTreatmentPlans[0].patientPortion | number: '0.2-2'}}
                        </div>

                        <div style="text-align: center;">
                            <button mat-icon-button (click)="selectPlan(0)">
                                <fa-icon style="color: #3F91CD; font-size: 24px;"
                                         [icon]="faSquare"
                                         *ngIf="!selectedTreatmentPlans[0].isSelected">
                                </fa-icon>
                                <fa-icon style="color: #3F91CD; font-size: 24px;"
                                         [icon]="faCheckCircle"
                                         *ngIf="selectedTreatmentPlans[0].isSelected">
                                </fa-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>
                <div class="col-5">
                    <ng-container *ngIf="selectedTreatmentPlans[1]">
                        <div class="plan-element-value"
                             style="font-weight: bold; color: #0ED251; font-size: 16px;">
                            {{selectedTreatmentPlans[1].patientPortion | number: '0.2-2'}}
                        </div>

                        <div style="text-align: center;">
                            <button mat-icon-button (click)="selectPlan(1)">
                                <fa-icon style="color: #3F91CD; font-size: 24px;"
                                         [icon]="faSquare"
                                         *ngIf="!selectedTreatmentPlans[1].isSelected">
                                </fa-icon>
                                <fa-icon style="color: #3F91CD; font-size: 24px;"
                                         [icon]="faCheckCircle"
                                         *ngIf="selectedTreatmentPlans[1].isSelected">
                                </fa-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="customize-plan-heading">Customize Your Payment Plan</div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'cash'}"
                         (click)="selectCustomizePlanTab('cash')">
                        <div>Pay in Full-cash</div>
                        <div>(5% discount)</div>
                    </div>
                </div>
                <div class="col-4">
                    <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'cc'}"
                         (click)="selectCustomizePlanTab('cc')">
                        <div>Pay in full-credit card</div>
                        <div>(2% discount)</div>
                    </div>
                </div>
                <div class="col-4">
                    <div [ngClass]="{'customize-plan-tab': true, 'customize-plan-tab-selected': selectedCustomizePlanTab == 'officeFinance'}"
                         (click)="selectCustomizePlanTab('officeFinance')">
                        <div>Interest Free</div>
                        <div>In-Office Financing</div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="selectedCustomizePlanTab == 'cash' && selectedTreatmentPlan">
                <div class="row">
                    <div class="col-12">
                        <div class="cash-progress-cont">
                            <div class="cash-progress">{{selectedTreatmentPlan.patientPortion | number: '0.2-2'}} minus 5% discount</div>
                            <div class="patient-portion-cont">
                                <div class="patient-portion">patient portion</div>
                                <div class="patient-portion-amount">{{cashPatientPortion(5) | number: '0.2-2'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedCustomizePlanTab == 'cc' && selectedTreatmentPlan">
                <div class="row" style="padding-top: 60px;">
                    <div class="col-12">
                        <div class="cash-progress">{{selectedTreatmentPlan.patientPortion | number: '0.2-2'}} minus 2% discount</div>
                        <div class="patient-portion-cont">
                            <div class="patient-portion-amount" style="color: #000000;">due now</div>
                        </div>
                    </div>
                </div>
                <div class="row" style="padding-top: 20px;">
                    <div class="col-9">
                        <div class="all-element">
                            <div class="all-element-label">patient portion</div>
                            <div class="all-element-value">{{selectedTreatmentPlan.patientPortion | number: '0.2-2'}}</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">&nbsp;</div>
                            <div class="all-element-value">&mdash;</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">paid in full discount</div>
                            <div class="all-element-value">{{paidInFullDiscount(2) | number: '0.2-2'}}</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">&nbsp;</div>
                            <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">insurance estimate*</div>
                            <div class="all-element-value">1500.00</div>
                        </div>
                    </div>
                    <div class="col-3" style="text-align: right">
                        <div class="all-element">
                            <div class="all-element-label">total contract</div>
                            <div class="all-element-value" style="color: #0ED251; font-size: 20px;">{{cashPatientPortion(2, true) | number: '0.2-2'}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="insurance-info">*Insurance is just an estimate. You are responsible for any amounts insurance does not pay</div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="selectedCustomizePlanTab == 'officeFinance' && selectedTreatmentPlan">
                <div class="row" style="padding-top: 20px;">
                    <div class="col-12">
                        <div class="progress-value">{{selectedTreatmentPlan.customAmountDown | number: '0.2-2'}}</div>
                        <div class="range">
                            <input type="range" 
                                   min="1" 
                                   [max]="selectedTreatmentPlan.patientPortion" 
                                   step="0.1"
                                   [value]="selectedTreatmentPlan.customAmountDown"
                                   (input)="changeCustomAmountDown($event.target.value)" />
                        </div>

                        <div class="office-finance-patient-portion">down payment</div>
                    </div>
                </div>
                <div class="row" style="padding-top: 20px;">
                    <div class="col-12">
                        <div class="progress-value">{{selectedTreatmentPlan.customPaymentAmountMonthly | number: '0.2-2'}}</div>
                        <div class="range">
                            <input type="range"
                                   min="1"
                                   [max]=selectedTreatmentPlan.treatmentLengthMonths+12
                                   step="1"
                                   [value]="selectedTreatmentPlan.customTreatmentLengthMonths"
                                   (input)="changeCustomTreamentLengh($event.target.value)" />
                        </div>
                        <div class="office-finance-patient-portion">number of months <span style="font-weight: bold;">{{selectedTreatmentPlan.customTreatmentLengthMonths}}</span></div>
                    </div>
                </div>
                <div class="row" style="padding-top: 20px;">
                    <div class="col-9">
                        <div class="all-element">
                            <div class="all-element-label">patient portion</div>
                            <div class="all-element-value">{{selectedTreatmentPlan.patientPortion}}</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">&nbsp;</div>
                            <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                        </div>

                        <div class="all-element">
                            <div class="all-element-label">insurance estimate*</div>
                            <div class="all-element-value">1500.00</div>
                        </div>

                        <ng-container *ngIf="selectedTreatmentPlan.customTreatmentLengthMonths > 18">
                            <div class="all-element">
                                <div class="all-element-label">&nbsp;</div>
                                <div class="all-element-value" style="font-size: 16px; margin-top: -3px;">+</div>
                            </div>

                            <div class="all-element">
                                <div class="all-element-label">management fee</div>
                                <div class="all-element-value" style="color: #000000;">350.00</div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-3" style="text-align: right">
                        <div class="all-element">
                            <div class="all-element-label">total contract</div>
                            <div class="all-element-value" style="color: #0ED251; font-size: 20px;">{{interestFreeCalculation() | number: '0.2-2'}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="insurance-info">*Insurance is just an estimate. You are responsible for any amounts insurance does not pay</div>
                    </div>
                </div>
            </ng-container>

            <div class="row" style="padding-top: 20px;">
                <div class="col-6">
                    <div class="not-now-btn">Not now</div>
                </div>

                <div class="col-6" style="text-align: right">
                    <button mat-flat-button style="background: #3F91CD; color: white;">
                        Sign and Pay Now
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
