import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from '../../../root-store';
import { AuthenticationStoreEffectsService, AuthStoreActions, AuthStoreSelectors } from '../../../root-store/auth-store';
import { API_BASE_URL, TokenDto } from '../api.service';

const authSkipUrls: string[] = [
    '/users/token',
    '/users/token/refresh',
];

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(
        private _store$: Store<RootStoreState.State>,
        @Inject(API_BASE_URL) private _apiBaseUrl: string,
        private _authStoreEffects: AuthenticationStoreEffectsService,
        private _router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let isMultipart = false;
        if (request.body instanceof FormData) {
            isMultipart = true;
        }

        if (!request.url.includes(this._apiBaseUrl) || authSkipUrls.some((s) => request.url.includes(s)))
            return this.setRequestHeader(request, isMultipart).pipe(switchMap((request) => next.handle(request)));

        const credentials$ = this._store$.select(AuthStoreSelectors.selectCredentials).pipe(take(1));
        const maskedTenantKey$ = this._store$.select(AuthStoreSelectors.selectedMaskedTenantKey).pipe(take(1));
        const time = Date.now();
        return credentials$.pipe(
            switchMap((credentials) => {
                if (credentials) {
                    //check if credentials valid. if not then try refresh
                    const isCredentialsExpired = time > credentials.expiresOn.valueOf();

                    //credentials present and not expired
                    if (!isCredentialsExpired) return of(credentials);
                    //credentials present but expired
                    else if (isCredentialsExpired) {
                        //dispatch placed into timeout function so that it will be processed after subscription
                        setTimeout(() => this._store$.dispatch(AuthStoreActions.RefreshRequest({ id: credentials.id, accessToken: credentials.accessToken, refreshToken: credentials.refreshToken })), 0);
                        //wait for refresh results
                        return this._authStoreEffects.refreshRequestEffect$.pipe(
                            take(1),
                            switchMap((result) =>
                                //if refresh successful, fetch new credentials, otherwise null
                                iif(() => result.type == AuthStoreActions.RefreshSuccess.type, credentials$, of(<TokenDto>null))
                            )
                        );
                    }
                }
                //Not authenticated
                else return of(<TokenDto>null);
            }),
            withLatestFrom(maskedTenantKey$),
            switchMap(([creds, maskedTenantKey]) => {
                return this.setRequestHeader(request, isMultipart, creds, maskedTenantKey).pipe(switchMap((request) => next.handle(request)));
            })
        );
    }

    setRequestHeader(request: HttpRequest<any>, isMultipart: boolean, credentials?: TokenDto | null | undefined, maskedTenantKey?: string | null | undefined): Observable<HttpRequest<any>> {
        const xMaskedTenantKey = 'X-MaskedTenant-Key';
        return this._store$.select(AuthStoreSelectors.selectTenantKey).pipe(
            take(1),
            map((tenantKey) => {
                let XTenantKey = '';
                if (request.headers.has('X-Tenant-Key') && request.headers.get('X-Tenant-Key') != '' && request.headers.get('X-Tenant-Key') != null) {
                    XTenantKey = request.headers.get('X-Tenant-Key');
                } else {
                    XTenantKey = tenantKey;
                }

                if (request.headers.has(xMaskedTenantKey) && request.headers.get(xMaskedTenantKey) != '' && request.headers.get(xMaskedTenantKey) != null) {
                    maskedTenantKey = request.headers.get(xMaskedTenantKey);
                } 

                let headers: { [key: string]: any } = {
                    'Content-Type': ['application/json-patch+json', '*'],
                    'Access-Control-Allow-Origin': '*',
                    'X-Tenant-Key': XTenantKey || ''
                };

                if (maskedTenantKey)
                    headers[xMaskedTenantKey] = maskedTenantKey;
                
                if (credentials !== null && credentials !== undefined) headers['Authorization'] = `${credentials.tokenType} ${credentials.accessToken}`;

                // eTag
                let etag = localStorage.getItem('etag');
                if (etag != null && etag != undefined) {
                    headers['If-Match'] = etag;
                }
                localStorage.removeItem('etag');

                // Remove content-type, when multi-part
                if (isMultipart) {
                    delete headers['Content-Type'];
                }

                if (request.headers) {
                    return request.clone({ setHeaders: headers });
                } else {
                    let httpOptions = {};

                    httpOptions = {
                        headers: new HttpHeaders(headers),
                    };

                    request = request.clone(httpOptions);
                    return request;
                }
            })
        );

    }
}
