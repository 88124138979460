import { Component, OnInit, OnDestroy } from '@angular/core';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { Subject } from 'rxjs';
import { PatientBasicDto, PatientCasePresentationClient, PatientContractPlanDto } from 'src/app/shared/services/api.service';


@Component({
	selector: 'patient-case-presentation',
	templateUrl: './patient-case-presentation.component.html',
	styleUrls: ['./patient-case-presentation.component.css']
})
export class PatientCasePresentationComponent implements OnInit, OnDestroy {
	private _destroy$: Subject<boolean> = new Subject<boolean>();

	faSquare = faSquare;
	faCheckCircle = faCheckCircle;

	patient:PatientBasicDto;

	treatmentList: PatientContractPlanDtoVM[]=[];
	selectedTreatmentPlans: PatientContractPlanDtoVM[] = [];
	
	selectedCustomizePlanTab: any = 'cash';
	selectedTreatmentPlan:PatientContractPlanDtoVM;

	constructor(
		private patientCasePresentationClient: PatientCasePresentationClient
	) { }

	ngOnDestroy() {
		this._destroy$.next(true);
	}

	ngOnInit(): void {
		this.getPatientCasePresentation();
	}

	getPatientCasePresentation(){
		this.patientCasePresentationClient.patientCasePresentation_GetOptions().subscribe(resp => {
			console.log(resp)
			this.treatmentList = [];
			resp.contractPlans.map(item => {
				this.treatmentList.push(new PatientContractPlanDtoVM(item));
			});

			this.patient = resp.patient;

			this.selectedTreatmentPlans = this.treatmentList;
			this.calculateTotalFee();
			this.calculatePatientPortion();
			this.selectPlan(0);
			
		})
	}

	selectTreatmentPlan(treatmentPlan){
		treatmentPlan.isSelected = treatmentPlan.isSelected ? false : true;

		if(treatmentPlan.isSelected){
			this.selectedTreatmentPlans.push(treatmentPlan);
		} else {
			let indx = this.selectedTreatmentPlans.findIndex(plan => plan.id == treatmentPlan.id);
			if(indx != -1){
				this.selectedTreatmentPlans.splice(indx, 1);
			}
		}

		if(this.selectedTreatmentPlans.length > 2){
			this.selectedTreatmentPlans[0].isSelected = false;
			this.selectedTreatmentPlans.splice(0, 1);
		}

	}

	selectCustomizePlanTab(tab){
		this.selectedCustomizePlanTab = tab;
	}

	selectPlan(indx){
		this.selectedTreatmentPlans.map((item, index) => {
			if(index != indx){
				item.isSelected = false;
			}
		})
		this.selectedTreatmentPlans[indx].isSelected = this.selectedTreatmentPlans[indx].isSelected ? false : true;

		if(this.selectedTreatmentPlans[indx].isSelected){
			this.selectedTreatmentPlan = this.selectedTreatmentPlans[indx];
			this.selectedTreatmentPlan.customTreatmentLengthMonths = this.selectedTreatmentPlan.treatmentLengthMonths;
			this.selectedTreatmentPlan.customAmountDown = this.selectedTreatmentPlan.amountDown;
			this.changeCustomTreamentLengh(this.selectedTreatmentPlan.treatmentLengthMonths);
			this.changeCustomAmountDown(this.selectedTreatmentPlan.amountDown);
		}
	}

	calculatePatientPortion(){
		let adjustments_0 = 0;
		let adjustments_1 = 0;

		this.selectedTreatmentPlans[0].discounts.map(discount => {
			adjustments_0 += Number(discount.amount);
		});

		this.selectedTreatmentPlans[1].discounts.map(discount => {
			adjustments_1 += Number(discount.amount);
		});

		this.selectedTreatmentPlans[0].patientPortion = Number(this.selectedTreatmentPlans[0].total) - adjustments_0;
		this.selectedTreatmentPlans[1].patientPortion = Number(this.selectedTreatmentPlans[1].total) + adjustments_1;
	}

	calculateTotalFee(){

		let totalFees_0 = 0;
		let totalFees_1 = 0;

		this.selectedTreatmentPlans[0].fees.map(fee => {
			totalFees_0 += Number(fee.amount);
		});

		this.selectedTreatmentPlans[1].fees.map(fee => {
			totalFees_1 += Number(fee.amount);
		});

		this.selectedTreatmentPlans[0].total = Number(this.selectedTreatmentPlans[0].recordsFee) + Number(this.selectedTreatmentPlans[0].treatmentFee) + totalFees_0;
		this.selectedTreatmentPlans[1].total = Number(this.selectedTreatmentPlans[1].recordsFee) + Number(this.selectedTreatmentPlans[1].treatmentFee) + totalFees_1;
	}


	cashPatientPortion(discAmount, hasIns?:boolean){

		let patientPortion = 0;

		if(hasIns){
			patientPortion = this.selectedTreatmentPlan.patientPortion - (discAmount/100)*this.selectedTreatmentPlan.patientPortion + 1500;
		} else {
			patientPortion = this.selectedTreatmentPlan.patientPortion - (discAmount/100)*this.selectedTreatmentPlan.patientPortion;
		}

		return patientPortion;
	}

	paidInFullDiscount(discAmount){
		return (discAmount/100)*this.selectedTreatmentPlan.patientPortion;
	}

	changeCustomTreamentLengh(value){
		this.selectedTreatmentPlan.customTreatmentLengthMonths = value;
		let paymentTobePaid = this.selectedTreatmentPlan.patientPortion - this.selectedTreatmentPlan.customAmountDown;
		this.selectedTreatmentPlan.customPaymentAmountMonthly = Number(paymentTobePaid) / Number(this.selectedTreatmentPlan.customTreatmentLengthMonths);
	}

	changeCustomAmountDown(value){
		this.selectedTreatmentPlan.customAmountDown = value;
		let paymentTobePaid = this.selectedTreatmentPlan.patientPortion - this.selectedTreatmentPlan.customAmountDown;
		this.selectedTreatmentPlan.customPaymentAmountMonthly = Number(paymentTobePaid) / Number(this.selectedTreatmentPlan.customTreatmentLengthMonths);
	}

	interestFreeCalculation(){
		let patientPortion = 0;
		if(this.selectedTreatmentPlan.customTreatmentLengthMonths > 18){
			patientPortion = this.selectedTreatmentPlan.patientPortion + 1500 + 350;
		} else {
			patientPortion = this.selectedTreatmentPlan.patientPortion + 1500;
		}

		return patientPortion;

	}

	treatmentChange(val: PatientContractPlanDtoVM): void {
		this.selectedTreatmentPlan = val;
	}
}

class PatientContractPlanDtoVM extends PatientContractPlanDto {
	isSelected: boolean = false;
	patientPortion:number = 0;
	customTreatmentLengthMonths:number = 0;
	customPaymentAmountMonthly:number = 0;
	customAmountDown:number = 0;
  }
