import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TextMaskModule } from 'angular2-text-mask';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AgmCoreModule } from '@agm/core';
import { MatRadioModule } from '@angular/material/radio';
import { NewPatientComponent } from './new-patient.component';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';
import { MomentOrdinalDateModule } from '../../../pipe/moment-ordinal-date/moment-ordinal-date.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { ngfModule } from 'angular-file';
import { MatListModule } from '@angular/material/list';

@NgModule({
    declarations: [NewPatientComponent],
    exports: [NewPatientComponent],
    imports: [
        CommonModule,
        MomentDateModule,
        MomentOrdinalDateModule,
        MatProgressBarModule,
        MatButtonModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        TextMaskModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatRadioModule,
        NgxCaptchaModule,
        MatAutocompleteModule,
        MatIconModule,
        ngfModule,
        MatListModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBrrJ1u0mq-NEzy_bblVvnlDWBYLmgIr-Q'
        })
    ]
})
export class NewPatientModule { }
