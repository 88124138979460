import { createAction, props } from '@ngrx/store';
import { LocationStoreEntity } from './state';

//LOAD PAGE
export const LoadRequest = createAction('[Locations] Load Request', props<{ page?: number; pageSize?: number }>());
export const LoadSuccess = createAction('[Locations] Load Success', props<{ locations: LocationStoreEntity[] }>());
export const LoadFailure = createAction('[Locations] Load Failure', props<{ error: string }>());

//SELECT
export const SelectRequest = createAction('[Locations] Select Request', props<{ id: number }>());
export const SelectSuccess = createAction('[Locations] Select Success', props<{ location: LocationStoreEntity }>());
export const SelectFailure = createAction('[Locations] Select Failure', props<{ error: string }>());
