import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { LOCATIONS_FEATURE_KEY } from './featurekey';
import { featureAdapter, LocationStoreEntity, State } from './state';

export const selectLocationsState: MemoizedSelector<object, State> = createFeatureSelector<State>(LOCATIONS_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectLocationsState);
export const selectLocationIds = selectIds;
export const selectLocationTotal = selectTotal;
export const selectAllLocations = selectAll;
export const selectAllLocationsEntities = selectEntities;

export const selectLocationById = (id: number) =>
  createSelector(selectAllLocations, (allLocations: LocationStoreEntity[]) => allLocations && allLocations.find((c) => c.id === id));

export const selectLocationsByIds = (ids: number[]) =>
  createSelector(selectAllLocations, (allLocations) => allLocations && allLocations.filter((l) => ids.includes(l.id)));

export const selectActiveLocations = createSelector(selectAllLocations, (allLocations: LocationStoreEntity[]) => {
  return allLocations && allLocations.filter((c) => c.isActive === true && c.isDeleted === false);
});

export const getSelectedLocationId = createSelector(selectLocationsState, (state) => state.selectedLocationId);

export const getSelectedLocation = createSelector(selectLocationsState, (state) => state.entities[state.selectedLocationId]);

export const selectLocationsError: MemoizedSelector<object, any> = createSelector(selectLocationsState, (state) => state.error);

export const selectLocationsIsLoading: MemoizedSelector<object, boolean> = createSelector(selectLocationsState, (state) => state.isLoading);
