import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';


@Component({
	selector: 'patient-reschedule',
	templateUrl: './patient-reschedule.component.html',
	styleUrls: ['./patient-reschedule.component.css']
})
export class PatientRescheduleComponent implements OnInit, OnDestroy {
	private _destroy$: Subject<boolean> = new Subject<boolean>();

	constructor() { }

	ngOnDestroy() {
		this._destroy$.next(true);
	}

	ngOnInit(): void {

	}
}
