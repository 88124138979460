import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ThemeColor } from '../../shared/models/theme/ThemeColor';
import { AUTH_FEATURE_KEY } from './featurekey';
import { featureAdapter, State } from './state';

export const getAuthState: MemoizedSelector<object, State> = createFeatureSelector<State>(AUTH_FEATURE_KEY);
export const selectIsLoading = createSelector(getAuthState, (state) => state.isLoading);
export const selectedMaskedTenantKey = createSelector(getAuthState, (state) => state.maskedTenantKey);

export const selectTenantKey = createSelector(getAuthState, (state) => state.tenantKey);
export const selectTenantName = createSelector(getAuthState, (state) => state.tenantName);

const { selectAll, selectEntities, selectIds, selectTotal } = featureAdapter.getSelectors(getAuthState);
export const selectAllTokens = selectAll;
export const selectTokenEntities = selectEntities;
export const selectTokenIds = selectIds;
export const selectTokenTotal = selectTotal;

export const selectedUserTokenId = createSelector(getAuthState, (state) => state.selectedTokenId);
export const selectCredentials = createSelector(selectedUserTokenId, selectAllTokens, (selectedUserTokenId, tokens) =>
    tokens.find((t) => t.id == selectedUserTokenId)
);

export const selectCredentialsById = (id: string) => createSelector(selectAllTokens, (tokens) => tokens.find((t) => t.id == id));
export const selectIsUserTokenExpired = (time: number, userTokenId?: string) => {
    const token = userTokenId ? selectCredentialsById(userTokenId) : selectCredentials
    return createSelector(
        token,
        (credentials) => credentials && time > credentials.expiresOn.valueOf()
    );
}

export const getThemeColors = createSelector(getAuthState, (state) => {
    let themeColor: ThemeColor = {
        primary: state.primaryColor,
        secondary: state.secondaryColor
    }

    return themeColor;
});

export const getHeaderText = createSelector(getAuthState, (state) => {
    return state.headerText;
});

export const getFooterText = createSelector(getAuthState, (state) => {
    return state.footerText;
});

export const getLogoLocationUrl = createSelector(getAuthState, (state) => {
    return state.logoLocationUrl;
});

export const getAppointmentDetail = createSelector(getAuthState, (state) => {
    return state.appointmentDetail;
});

export const getCommunicationDeskId = createSelector(getAuthState, (state) => {
    return state.communicationDeskId;
});

export const getLocation = createSelector(getAuthState, (state) => {
    return state.location
})

export const getSettings = createSelector(getAuthState, (state) => {
    return state.settings
})

