import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { CheckInAppointmentDto, ICheckInAppointmentDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<AppointmentStoreEntity> = createEntityAdapter<AppointmentStoreEntity>({
  sortComparer: (a: AppointmentStoreEntity, b: AppointmentStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<AppointmentStoreEntity> {
  page?: number;
  pageSize?: number;
  selectedAppointmentId?: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedAppointmentId: null,
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null
});

export class AppointmentStoreEntity extends CheckInAppointmentDto {
  constructor(data?: ICheckInAppointmentDto) {
    super(data);
  }
}
