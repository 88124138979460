import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentDetailsModule } from './appointment-details/appointment-details.module';
import { ConfirmationModule } from './confirmation/confirmation.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './shared/services/auth';
import { RootStoreModule } from './root-store';
import { AuthenticatorModule } from './authenticator/authenticator.module';
import { API_BASE_URL } from './shared/services/api.service';
import { environment } from '../environments/environment';
import { EventHubServiceModule } from './shared/services/event-hub';
import { MarketingDetailsModule } from './marketing-details/marketing-details.module';
import { PatientPortalModule } from './patient-portal/patient-portal.module';
import { ContractsModule } from './contracts/contracts.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AuthenticatorModule,
        DashboardModule,
        BrowserAnimationsModule,
        NgbModule,
        AppointmentDetailsModule,
        ConfirmationModule,
        EventHubServiceModule,
        StoreDevtoolsModule.instrument({ logOnly: environment.production }),
        RootStoreModule,
        MarketingDetailsModule,
        PatientPortalModule,
        ContractsModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: API_BASE_URL, useValue: environment.apiUrl },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
