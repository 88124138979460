import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { NewPatientModule } from './dashboard/new-patient/new-patient.module';
import { PatientCheckInModule } from './dashboard/patient-check-in/patient-check-in.module';
import { PatientConfirmModule } from './dashboard/patient-confirm-appointment/patient-confirm.module';
import { PatientFormModule } from './dashboard/patient-form/patient-form.module';
import { SecureEmailModule } from './dashboard/secure-email/secure-email.module';
import { MarketingModule } from './dashboard/marketing/marketing.module';
import { PatientScheduleModule } from './dashboard/patient-schedule-appointment/patient-schedule.module';
import { PatientRescheduleModule } from './dashboard/patient-reschedule-appointment/patient-reschedule.module';
import { PatientCasePresentationModule } from './dashboard/patient-case-presentation/patient-case-presentation.module';

const routes: Routes = [
    { path: '', component: DashboardComponent },
    { path: 'dashboard', component: DashboardComponent },
];


@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        NewPatientModule,
        MarketingModule,
        PatientCheckInModule,
        PatientCasePresentationModule,
        PatientRescheduleModule,
        PatientScheduleModule,
        PatientConfirmModule,
        PatientFormModule,
        SecureEmailModule,
        MarketingModule
    ]
})
export class DashboardModule { }
