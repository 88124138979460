import { EntityAdapter, createEntityAdapter, EntityState } from "@ngrx/entity";
import { LocationBaseDto, ILocationBaseDto } from 'src/app/shared/services/api.service';

export const featureAdapter: EntityAdapter<LocationStoreEntity> = createEntityAdapter<LocationStoreEntity>({
  selectId: patient => patient.id,
  sortComparer: (a: LocationStoreEntity, b: LocationStoreEntity): number => a.id - b.id
});

export interface State extends EntityState<LocationStoreEntity> {
  page?: number;
  pageSize?: number;
  isLoading?: boolean;
  error?: any;
  selectedLocationId?: number;
}

export const initialState: State = featureAdapter.getInitialState({
  page: 0,
  pageSize: 20,
  isLoading: false,
  error: null,
  selectedLocationId: null
});

export class LocationStoreEntity extends LocationBaseDto {
  constructor(data?: ILocationBaseDto) {
    super(data);
  }
}
