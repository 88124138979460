import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mapTo, share, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NewPatientClient, RefreshTokenModel, TenantKeyDto, TokenDto, UserClient, UserTenantModel, PatientCheckInClient, PortalTypeEnum, MarketingClient, PatientSelfScheduleClient } from '../../shared/services/api.service';
import { AuthenticationService } from '../../shared/services/auth/authentication.service';
import * as AuthStoreActions from './actions';
import * as RootStoreState from '../root-state';
import * as AuthStoreSelectors from './selectors';
import * as _ from 'lodash';
import { Theme } from '../../shared/models/theme/ThemeColor';
import { ThemeService } from '../../shared/services/theme/theme.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationStoreEffectsService implements OnInitEffects {
    private _allTokens$ = this._store$.select(AuthStoreSelectors.selectAllTokens);
    private _selectedUserTokenId$ = this._store$.select(AuthStoreSelectors.selectedUserTokenId);

    constructor(
        private _actions$: Actions,
        private _userClient: UserClient,
        private _newPatientClient: NewPatientClient,
        private _marketingClient: MarketingClient,
        private _authService: AuthenticationService,
        private _store$: Store<RootStoreState.State>,
        private _themeService: ThemeService,
        private _patientCheckInClient: PatientCheckInClient,
        private _patientSelfScheduleClient: PatientSelfScheduleClient,
        private _router: Router
    ) { }

    ngrxOnInitEffects(): Action {
        return AuthStoreActions.AuthenticatorInit();
    }

    initEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.AuthenticatorInit),
            mapTo(this._authService.getBrowserTokenStorage()),
            filter(([tokenStorage, selectedTokenId, maskedTenantKey, tenant]) => {
                return !!tokenStorage && tokenStorage.length > 0 && !!selectedTokenId && !!tenant && !!maskedTenantKey;
            }),
            switchMap(([tokenStorage, selectedTokenId, maskedTenantKey, tenant]) => {
                const tokens: TokenDto[] = tokenStorage.map((i) => TokenDto.fromJS(i));
                return of(
                    AuthStoreActions.SetTenant({ tenantName: tenant && tenant.name, tenantKey: tenant && tenant.key }),
                    AuthStoreActions.AuthenticateSuccess({ userCredentials: tokens.find((t) => t.id == selectedTokenId), maskedTenantKey: maskedTenantKey }),
                    AuthStoreActions.AddTokens({ tokens: tokens.filter((t) => t.id != selectedTokenId) })
                );
            })
        )
    );

    authenticateRequestEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.AuthenticateRequest),
            switchMap((action) =>
                this._userClient.user_Token(action.authModel, null, action.maskedTenantKey).pipe(
                    map((result) => {
                        const userTenant = new UserTenantModel({
                            email: result.email
                        });

                        return {
                            userTenant: userTenant,
                            token: result,
                            maskedTenantKey: action.maskedTenantKey
                        };
                    }),
                    catchError((error) => of(AuthStoreActions.AuthenticateFailure({ error })))
                )
            ),
            switchMap((action: any) => {
                return action.error ?
                    of(action) :
                    this._userClient.user_GetOptions(action.userTenant, null, action.maskedTenantKey).pipe(
                        map((result) => {
                            const tenant: TenantKeyDto = _.first(result.tenants);
                            return { userCredentials: action.token, maskedTenantKey: action.maskedTenantKey, tenant: tenant };
                        }),
                        catchError((error) => of(AuthStoreActions.AuthenticateFailure({ error })))
                    )
            }),
            switchMap((action: any) => {
                if (action.error) {
                    return of(AuthStoreActions.AuthenticateFailure({ error: action.error }));
                }
                else if (action.userCredentials && action.userCredentials.portalType == PortalTypeEnum.PatientContractPlanUrl) {
                    if (action.userCredentials && action.userCredentials.bypassSecurity) {
                        return of(
                            AuthStoreActions.SetTenant(action.tenant),
                            AuthStoreActions.AuthenticatePatientPortalBypassSuccess({
                                userCredentials: action.userCredentials,
                                maskedTenantKey: action.maskedTenantKey
                            }));
                    }
                    else {
                        return of(
                            AuthStoreActions.SetTenant(action.tenant),
                            AuthStoreActions.AuthenticatePatientPortalRequest({
                                userCredentials: action.userCredentials,
                                maskedTenantKey: action.maskedTenantKey
                            }));
                    }
                }

                return of(
                    AuthStoreActions.SetTenant(action.tenant),
                    AuthStoreActions.AuthenticateSuccess(action)
                );
            }),
            share()
        )
    );

    authenticatePatientPortalRequestEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.AuthenticatePatientPortalRequest),
            withLatestFrom(this._allTokens$, this._selectedUserTokenId$),
            tap(([result, tokens, selectedTokenId]) => {
                this._authService.setBrowserMaskedTenantKey(result.maskedTenantKey);
                this._authService.setBrowserTokenStorage(tokens);
                this._authService.setBrowserSelectedTokenStorage(selectedTokenId);
                this._router.navigate(['/patient', 'login']);
            })
        ),
        { dispatch: false }
    );

    authenticatePatientPortalBypassSuccessEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.AuthenticatePatientPortalBypassSuccess),
            withLatestFrom(this._allTokens$, this._selectedUserTokenId$),
            tap(([result, tokens, selectedTokenId]) => {
                this._authService.setBrowserMaskedTenantKey(result.maskedTenantKey);
                this._authService.setBrowserTokenStorage(tokens);
                this._authService.setBrowserSelectedTokenStorage(selectedTokenId);
                this._router.navigate(['/contracts', 'quote']);
            })
        ),
        { dispatch: false }
    );

    authenticateSuccessEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.AuthenticateSuccess),
            withLatestFrom(this._allTokens$, this._selectedUserTokenId$),
            tap(([result, tokens, selectedTokenId]) => {
                this._authService.setBrowserMaskedTenantKey(result.maskedTenantKey);
                this._authService.setBrowserTokenStorage(tokens);
                this._authService.setBrowserSelectedTokenStorage(selectedTokenId);
            }),
            switchMap(([result]) => {

                if (this._authService.getPortalType() == PortalTypeEnum.PatientCheckInUrl) {
                    return of(AuthStoreActions.PatientCheckInOptionRequest({ maskedTenantKey: result.maskedTenantKey }));
                }

                if (this._authService.getPortalType() == PortalTypeEnum.MarketingUrl) {
                    return of(AuthStoreActions.MarketingOptionRequest({ maskedTenantKey: result.maskedTenantKey }));
                }

                if (this._authService.getPortalType() == PortalTypeEnum.PatientRescheduleUrl) {
                    return of(AuthStoreActions.PatientRescheduleOptionRequest({ maskedTenantKey: result.maskedTenantKey }));
                }

                if (this._authService.getPortalType() == PortalTypeEnum.PatientSelfScheduleUrl) {
                    return of(AuthStoreActions.PatientSelfScheduleOptionRequest({ maskedTenantKey: result.maskedTenantKey }));
                }

                if (this._authService.getPortalType() == PortalTypeEnum.PatientContractPlanUrl) {
                    if (result.userCredentials && result.userCredentials.bypassSecurity && result.userCredentials.isQuote) {
                        return of(AuthStoreActions.AuthenticatePatientPortalBypassSuccess({
                            userCredentials: result.userCredentials,
                            maskedTenantKey: result.maskedTenantKey
                        }));
                    }
                    else {
                        return of(AuthStoreActions.PatientContractPlanRequest({ maskedTenantKey: result.maskedTenantKey }));
                    }
                }
                
                if (this._authService.getPortalType() == PortalTypeEnum.NewPatientUrl) {
                    return of(AuthStoreActions.NewPatientOptionRequest({ maskedTenantKey: result.maskedTenantKey }));
                }
            }),
            share()
        )
    );

    authenticateFailureEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.AuthenticateFailure),
            tap(() => {
                this._authService.clearBrowserStorage();
            }),
            share()
        ),
        { dispatch: false }
    );

    newPatientOptionRequestEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.NewPatientOptionRequest),
            switchMap((action: any) =>
                this._newPatientClient.newPatient_GetOptions(null, action.maskedTenantKey).pipe(
                    map((result) => {
                        let theme: Theme = {
                            properties: {
                                "--new-patient-url-primary": result.settings.primaryColorCode,
                                "--new-patient-url-secondary": result.settings.secondaryColorCode,
                            }
                        }
                        this._themeService.setTheme(theme);

                        return result;
                    }),
                    catchError((error) => of(AuthStoreActions.AuthenticateFailure({ error })))
                )
            ),
            switchMap((action: any) => {
                return action.error ?
                    of(AuthStoreActions.NewPatientOptionFailure({ error: action.error })) :
                    of(AuthStoreActions.NewPatientOptionSuccess({ newPatientUrlOption: action }));
            }),
            share()
        )
    );

    marketingOptionRequestEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.MarketingOptionRequest),
            switchMap((action: any) =>
                this._marketingClient.marketing_GetOptions(null, action.maskedTenantKey).pipe(
                    map((result) => {
                        let theme: Theme = {
                            properties: {
                                "--new-patient-url-primary": result.settings.primaryColorCode,
                                "--new-patient-url-secondary": result.settings.secondaryColorCode,
                            }
                        }
                        this._themeService.setTheme(theme);

                        return result;
                    }),
                    catchError((error) => of(AuthStoreActions.AuthenticateFailure({ error })))
                )
            ),
            switchMap((action: any) => {
                return action.error ?
                    of(AuthStoreActions.MarketingOptionFailure({ error: action.error })) :
                    of(AuthStoreActions.MarketingOptionSuccess({ marketingUrlOption: action }));
            }),
            share()
        )
    );

    refreshRequestEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.RefreshRequest),
            //exhaustMap should ignore any other refresh request while the http request is being processed
            //this is important as components will likely be trying to load asynchronously and wont know if another place ends up requesting the refresh
            exhaustMap((action) => {
                return this._userClient.user_TokenRefresh(
                    new RefreshTokenModel({ accessToken: action.accessToken, refreshToken: action.refreshToken }),
                    null,
                    this._authService.getBrowserMaskedTenantKey()
                ).pipe(
                    map((result) => {
                        let userCredentials: Partial<TokenDto> = {
                            accessToken: result.accessToken,
                            expiresOn: result.expiresOn,
                            refreshToken: result.refreshToken
                        };
                        return AuthStoreActions.RefreshSuccess({
                            id: action.id,
                            userCredentials: userCredentials,
                        })
                    }),
                    catchError((error) => {
                        return of(AuthStoreActions.RefreshFailure({ id: action.id, error }));
                    })
                )
            }),
            //share prevents token refresh from being called multiple times if there are multiple http calls waiting
            share()
        )
    );

    setTenentEffect$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthStoreActions.SetTenant),
                tap((action) => this._authService.setBrowserTenantStorage({ name: action.tenantName, key: action.tenantKey })),
                share()
            ),
        { dispatch: false }
    );

    PatientCheckInOptionRequestEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.PatientCheckInOptionRequest),
            switchMap((action: any) =>
                this._patientCheckInClient.patientCheckIn_GetOptions(null, action.maskedTenantKey).pipe(
                    map((result) => {
                        return result;
                    }),
                    catchError((error) => of(AuthStoreActions.AuthenticateFailure({ error })))
                )
            ),
            switchMap((action: any) => {
                return action.error ?
                    of(AuthStoreActions.PatientCheckInOptionFailure({ error: action.error })) :
                    of(AuthStoreActions.PatientCheckInOptionSuccess({ patientCheckInOption: action }));
            }),
            share()
        )
    );

    PatientSelfScheduleOptionRequestEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.PatientSelfScheduleOptionRequest),
            switchMap((action: any) =>
                this._patientSelfScheduleClient.patientSelfSchedule_GetOptions(null, action.maskedTenantKey).pipe(
                    map((result) => {
                        return result;
                    }),
                    catchError((error) => of(AuthStoreActions.AuthenticateFailure({ error })))
                )
            ),
            switchMap((action: any) => {
                return action.error ?
                    of(AuthStoreActions.PatientSelfScheduleOptionFailure({ error: action.error })) :
                    of(AuthStoreActions.PatientSelfScheduleOptionSuccess({ patientSelfScheduleOption: action }));
            }),
            share()
        )
    );

    loginRequestEffect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthStoreActions.LoginRequest),
            switchMap((action) =>
                this._userClient.user_Token(action.authModel).pipe(
                    map((result) => {
                        let token = this._authService.getBrowserToken();
                        if (!token) {
                            return AuthStoreActions.LoginFailure({ error: "Invalid token" });
                        }
                        token.id = result.id;
                        token.accessToken = result.accessToken;
                        token.refreshToken = result.refreshToken;
                        token.expiresOn = result.expiresOn;
                        token.tokenType = result.tokenType;
                        this._authService.setBrowserTokenInfo(token, token.id);
                        return AuthStoreActions.LoginSuccess({ userCredentials: token });
                    }),
                    catchError((error) => of(AuthStoreActions.LoginFailure({ error })))
                )
            ),
            share()
        )
    );
}
