import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { LoginComponent } from './login/login.component';
import { PatientPortalComponent } from './patient-portal/patient-portal.component';

const routes: Routes = [
    {
        path: '',
        component: PatientPortalComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'error',
                component: ErrorComponent,
            },
            {
                path: 'contracts',
                loadChildren: '../contracts/contracts.module#ContractsModule',
            },
            //{
            //  path: 'form-submission',
            //  loadChildren: '../form-submission/form-submission.module#FormSubmissionModule',
            //},
            //{
            //  path: 'download-attachment-portal',
            //  loadChildren: '../download-attachment-portal/download-attachment-portal.module#DownloadAttachmentPortalModule',
            //}
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PatientPortalRoutingModule { }
