import { NgModule } from '@angular/core';
import { OrderPipe } from './order.pipe';

@NgModule({
    declarations: [
        OrderPipe
    ],
    imports: [],
    exports: [
        OrderPipe
    ]
})
export class OrderModule { }
