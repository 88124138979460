import { Directive, Input, NgModule } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

export class EvolutionValidators {
  static matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value ? null : { mismatch: true };
    };
  }

  static userPassword(control: AbstractControl): ValidationErrors | null {
    const passwordMinLength = 6;
    let errors: ValidationErrors = null;
    if (control.value) {
      const value: string = control.value;

      //Min Length [passwordMinLength]
      if (value.length < passwordMinLength) errors = { passwordLength: true };

      //Uppercase 1
      if (!/[A-Z]/.test(value)) {
        errors = !errors ? { passwordUpper: passwordMinLength } : { ...errors, passwordUpper: passwordMinLength };
      }

      //Lowercase 1
      if (!/[a-z]/.test(value)) {
        errors = !errors ? { passwordLower: true } : { ...errors, passwordLower: true };
      }

      //Number 1
      if (!/[0-9]/.test(value)) {
        errors = !errors ? { passwordNumber: true } : { ...errors, passwordNumber: true };
      }

      //Special Character 1
      //Credit to https://regexr.com/3bpa8 for expression
      if (!/[^A-z\s\d][\\\^]?/.test(value)) {
        errors = !errors ? { passwordSpecialCharacter: true } : { ...errors, passwordSpecialCharacter: true };
      }
    } else {
      return { passwordLength: passwordMinLength };
    }
    return errors;
  }
}

@Directive({
  selector: '[validateMinMax]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidateMinMaxDirective,
      multi: true,
    },
  ],
})
export class ValidateMinMaxDirective implements Validator {
  @Input('validateMinMax') set minMax(value: [number, number]) {
    [this._min, this._max] = value;
  }
  private _min: number;
  private _max: number;

  validate(control: AbstractControl): { [key: string]: any } | null {
    let result: { min?: boolean; max?: boolean } = {};
    let value = Number(control.value);
    if (this._min != null && value < this._min) {
      result.min = true;
    }
    if (this._max != null && value > this._max) {
      result.max = true;
    }
    return result.min || result.max ? result : null;
  }
}

@NgModule({
  declarations: [ValidateMinMaxDirective],
  exports: [ValidateMinMaxDirective],
})
export class EvoValidatorsModule {}
