import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { INSURANCE_COMPANY_FEATURE_KEY } from './featurekey';
import { featureAdapter, InsuranceCompanyStoreEntity, State } from './state';

export const selectInsuranceCompaniesState: MemoizedSelector<object, State> = createFeatureSelector<State>(INSURANCE_COMPANY_FEATURE_KEY);

const { selectAll, selectIds, selectTotal, selectEntities } = featureAdapter.getSelectors(selectInsuranceCompaniesState);
export const selectInsuranceCompanyIds = selectIds;
export const selectInsuranceCompanyTotal = selectTotal;
export const selectAllInsuranceCompanies = selectAll;
export const selectAllInsuranceCompaniesEntities = selectEntities;


export const selectInsuranceCompanyById = (id: number) =>
  createSelector(
    selectAllInsuranceCompanies,
    (allInsuranceCompanies: InsuranceCompanyStoreEntity[]) => {
      if (allInsuranceCompanies) {
        return allInsuranceCompanies.find(c => c.id === id);
      } else {
        return null;
      }
    }
  );

export const selectInsuranceCompaniesError: MemoizedSelector<object, any> = createSelector(
  selectInsuranceCompaniesState,
  (state) => state.error
);

export const selectInsuranceCompaniesIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectInsuranceCompaniesState,
  (state) => state.isLoading
);

