import { Component, ViewContainerRef } from '@angular/core';
import { EventHubService } from './shared/services/event-hub/event-hub.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'NewPatientURL';
  constructor(eventHub: EventHubService, viewContainerRef: ViewContainerRef) {
    eventHub.Start(viewContainerRef);
  }
}
