import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AppointmentDetailsComponent } from './appointment-details/appointment-details.component';
import { MatButtonModule } from '@angular/material/button';
import { TextMaskModule } from 'angular2-text-mask';
import { MomentDateModule } from '../pipe/moment-date/moment-date.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { ngfModule } from 'angular-file';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

const routes: Routes = [
    { path: '', component: AppointmentDetailsComponent },
    { path: 'appointment-details', component: AppointmentDetailsComponent },
];

@NgModule({
    declarations: [AppointmentDetailsComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatRadioModule,
        MatButtonModule,
        TextMaskModule,
        MomentDateModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatListModule,
        MatTooltipModule,
        MatIconModule,
        ngfModule,
        MatAutocompleteModule,
        FormsModule
    ]
})
export class AppointmentDetailsModule { }
