<mat-progress-bar mode="indeterminate" *ngIf="!appointment || isWorking"></mat-progress-bar>
<ng-container *ngIf="appointment">
    <div class="component-container">
        <div class="location-name">
            Confirm appointment
        </div>

        <div class="info-text" style="margin-top: 15px; margin-bottom: 15px;">
            {{appointment?.patientFirstName}} {{appointment?.patientLastName}}
        </div>
        
        <div class="info-text">
            {{appointment?.startTime | momentDate: 'MMMM d' : appointment?.locationTimeZone}}
            at
            {{appointment?.startTime | momentDate: 'h:mm a' : appointment?.locationTimeZone}}
        </div>
        <div class="btn-container"
             *ngIf="appointmentStatus == appointmentStatusEnum.Active" [ngStyle]="{'justify-content': appointment.isCancelAllowed ? 'space-between' : 'center'}">
            <button mat-stroked-button
                    class="stroked-btn"
                    (click)="cancelAppointment()" *ngIf="appointment.isCancelAllowed">
                Can't make it
            </button>
            <button mat-stroked-button
                    class="stroked-btn"
                    (click)="confirmAppointment()">
                I'll be there!
            </button>
        </div>
        <div class="confirmation-message"
             *ngIf="appointmentStatus != appointmentStatusEnum.Active">
            <ng-container *ngIf="appointmentStatus == appointmentStatusEnum.Confirmed">
                You are already confirmed. See you soon!
            </ng-container>
            <ng-container *ngIf="appointmentStatus == appointmentStatusEnum.Rescheduled">
                This appointment has been rescheduled.
                Call office for more details.
            </ng-container>
            <ng-container *ngIf="appointmentStatus == appointmentStatusEnum.Canceled">
                Sorry this appointment has been canceled. Please call the office to reschedule.
            </ng-container>
            <ng-container *ngIf="appointmentStatus == appointmentStatusEnum.Passed">
                This appointment has already passed.
            </ng-container>

            <ng-container *ngIf="!appointment.isCancelAllowed">
                Please call the office if you are unable to make this appointment.
            </ng-container>


        </div>
    </div>

    <ng-template #confirmingDialog>
        <h4 matDialogTitle>
            Thanks!
        </h4>
        <mat-dialog-content>
            Thanks for confirming your appointment.
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-stroked-button
                    class="stroked-ok-btn"
                    (click)="dialogRef.close(null)"
                    style="border: none;">
                OK
            </button>
        </mat-dialog-actions>
    </ng-template>

    <ng-template #cancelDialog>
        <h4 matDialogTitle>
            Cancel Appointment
        </h4>
        <mat-dialog-content>
            Clicking this will remove you from the schedule...Continue
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-stroked-button
                    class="stroked-cancel-btn"
                    (click)="dialogRef.close(null)">
                No
            </button>
            <button mat-stroked-button
                    class="stroked-ok-btn"
                    (click)="dialogRef.close(true)">
                Yes
            </button>
        </mat-dialog-actions>
    </ng-template>
</ng-container>
