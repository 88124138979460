<form [formGroup]="patientPinRequestFormGroup" novalidate (ngSubmit)="requestPin()" *ngIf="showPinRequest">
  <p>In order to protect your privacy, we need to verify your identity.</p><br />
  <p>
    If accessing via <b>email</b>, please click email to receive a pin via email.<br />
    If accessing via <b>phone</b>, please click phone to receive a pin.
  </p>
  <div class="method-select"
       *ngIf="!verificationMethod || !multipleEmail">
    <label>How would you like to verify your identity?</label>
    <mat-radio-group formControlName="method">
      <mat-radio-button value="phone">Phone</mat-radio-button>
      <mat-radio-button value="email">Email</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="verification-form"
       *ngIf="!multipleEmail">
    <span *ngIf="verificationMethod == 'email'"
          style="color: red">
      Please enter your email to verify your identity
    </span>
    <mat-form-field [class.hidden]="this.patientPinRequestFormGroup.get('method').value != 'phone'" appearance="outline">
      <mat-label>Phone Number</mat-label>
      <input matInput formControlName="phone" placeholder="Phone Number" autocomplete="phone"
             [required]="this.patientPinRequestFormGroup.get('method').value === 'phone'" />
    </mat-form-field>
    <mat-form-field [class.hidden]="this.patientPinRequestFormGroup.get('method').value != 'email'" appearance="outline">
      <mat-label>Email Address</mat-label>
      <input matInput formControlName="email" placeholder="Email" autocomplete="email"
             [required]="this.patientPinRequestFormGroup.get('method').value === 'email'" />
    </mat-form-field>
  </div>
  <div class="verification-form"
       style="margin-top: 10px; margin-bottom: 10px;"
       *ngIf="multipleEmail">
    <span style="color: red">
      Please select your email to verify your identity
    </span>
    <mat-radio-group formControlName="email">
      <div *ngFor="let email of emails"
           style="margin-top: 10px;">
        <mat-radio-button [value]="email">
          {{email}}
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <button *ngIf="this.patientPinRequestFormGroup.get('method').value" mat-stroked-button color="primary" type="submit"
          [disabled]="patientPinRequestFormGroup.invalid">
    Request Pin
  </button>

  <mat-error *ngIf="patientPinRequestError">{{patientPinRequestError}}</mat-error>
</form>

<form [formGroup]="patientLoginFormGroup" novalidate (ngSubmit)="login()" *ngIf="showLogin">
  <label>A Pin has been sent. Please confirm by entering the Pin below.</label>
  <div class="verification-form">
    <mat-form-field *ngIf="this.patientPinRequestFormGroup.get('method').value === 'phone'">
      <mat-label>Phone Number</mat-label>
      <input matInput placeholder="Phone Number" formControlName="phone" />
    </mat-form-field>
    <mat-form-field *ngIf="this.patientPinRequestFormGroup.get('method').value === 'email'">
      <mat-label>Email Address</mat-label>
      <input matInput placeholder="Email" formControlName="email" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Pin</mat-label>
      <input matInput formControlName="pin" placeholder="000000" autocomplete="off">
    </mat-form-field>
  </div>
  <button mat-stroked-button color="primary" type="submit" [disabled]="patientLoginFormGroup.invalid">Continue</button>
  &nbsp;
  <button mat-stroked-button color="warn" type="button" (click)="goBack()">Go Back</button>

  <mat-error *ngIf="patientLoginError">{{patientLoginError}}</mat-error>
</form>

<mat-progress-bar mode="indeterminate" *ngIf="isWorkingCount > 0"></mat-progress-bar>
