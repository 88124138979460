import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientRescheduleComponent } from './patient-reschedule.component';

@NgModule({
	declarations: [PatientRescheduleComponent],
	exports: [PatientRescheduleComponent],
	imports: [
		CommonModule
	]
})
export class PatientRescheduleModule { }
