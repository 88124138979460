import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, pipe } from 'rxjs';
import { catchError, filter, map, mapTo, share, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { LocationStoreEntity } from '.';
import { LocationBaseDto, LocationClient, NewPatientClient } from '../../shared/services/api.service';
import { AuthStoreActions, AuthStoreSelectors } from '../auth-store';
import { State } from '../root-state';
import * as LocationsStoreActions from './actions';
import * as LocationsStoreSelectors from './selectors';

@Injectable({ providedIn: 'root' })
export class LocationsStoreEffects {
    constructor(
        private store$: Store<State>,
        private actions$: Actions,
        private _locationClient: LocationClient
    ) { }

    selectSuccessEffect$: Observable<Action> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(LocationsStoreActions.SelectSuccess),
                tap((action) => {
                    sessionStorage.setItem('selectedLocationId', action.location.id.toString());
                })
            ),
        { dispatch: false }
    );

    loadSuccessEffect$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsStoreActions.LoadSuccess),
            withLatestFrom(this.store$.select(AuthStoreSelectors.selectCredentials)),
            switchMap(([action, credentials]) => {
                const selectedLocationId = parseInt(sessionStorage.getItem('selectedLocationId'));

                if (!!selectedLocationId) {
                    return of(LocationsStoreActions.SelectRequest({ id: selectedLocationId }));
                }

                return of(LocationsStoreActions.SelectRequest({ id: credentials.defaultLocationId }));
            })
        )
    );

    loadRequestNewPatientEffect$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsStoreActions.LoadRequest),
            withLatestFrom(this.store$.select((state) => state.Locations)),
            switchMap(([action, state]) =>
                this._locationClient.location_GetLocations(action.pageSize || state.pageSize, action.page || state.page).pipe(
                    map((result) => LocationsStoreActions.LoadSuccess({ locations: result })),
                    catchError((error) => of(LocationsStoreActions.LoadFailure({ error })))
                )
            )
        )
    );

    loadRequestCheckInEffect$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthStoreActions.PatientCheckInOptionSuccess),
            withLatestFrom(this.store$.select((state) => state.Locations)),
            map(([action, state]) =>
                LocationsStoreActions.SelectSuccess({ location: action.patientCheckInOption.location })
            )
        )
    );

    loadRequestSelfScheduleEffect$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthStoreActions.PatientSelfScheduleOptionSuccess),
            withLatestFrom(this.store$.select((state) => state.Locations)),
            map(([action, state]) =>
                LocationsStoreActions.SelectSuccess({ location: action.patientSelfScheduleOption.location })
            )
        )
    );

    selectRequestEffect$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(LocationsStoreActions.SelectRequest),
            withLatestFrom(this.store$.select(LocationsStoreSelectors.selectLocationsState)),
            switchMap(([action, state]) => {
                if (state.ids.length > 0 && state.entities[action.id]) {
                    return of(LocationsStoreActions.SelectSuccess({ location: state.entities[action.id] }));
                } else if (state.ids.length > 0 && action.id == null) {
                    return of(LocationsStoreActions.SelectSuccess({ location: state.entities[state.ids[0]] }));
                } else {
                    return of(LocationsStoreActions.SelectFailure({ error: 'No Locations loaded' }));
                }
            })
        )
    );
}
