import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { take, takeUntil } from 'rxjs/operators';
import html2pdf from 'html2pdf.js';
import {
    ContractClient,
    ContractDto,
    ContractInformationDto,
    ContractSectionItemDto,
    ContractSectionTypeEnum,
    ErrorDetails,
    FileParameter,
    SendContractPlanPaymentMethodEnum,
    StorageContentTypeEnum,
} from 'src/app/shared/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignatureDialogComponent, SignatureDialogData, SignatureDialogResult, SignatureType } from '../../shared/signature-dialog/signature-dialog.component';
import { AuthenticationService } from '../../shared/services/auth';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-sign-contract',
    templateUrl: './sign-contract.component.html',
    styleUrls: ['../contracts.component.scss', './sign-contract.component.scss'],
})
export class SignContractComponent implements OnInit, OnDestroy {
    @ViewChild('contractContent', { static: false }) contractContent: ElementRef;
    isLoadingContent: boolean = false;
    errorMessage: string;
    _contractPlanId: number;
    contractData: ContractInformationDto;
    paymentPlanOption: SendContractPlanPaymentMethodEnum;
    contractFinalSignature: string;
    paragraphSignatures: { [x: number]: string } = {};

    ContractSectionTypeEnum = ContractSectionTypeEnum;
    SendContractPlanPaymentMethodEnum = SendContractPlanPaymentMethodEnum;
    today = Date.now();
    isGeneratingContract: boolean = false;
    contractSectionHeaders: ContractSectionItemDto[];
    contractSectionBodies: ContractSectionItemDto[];
    private _destroy$: Subject<boolean> = new Subject<boolean>();
    constructor(
        private _matDialog: MatDialog,
        private _contractClient: ContractClient,
        private _activatedRoute: ActivatedRoute,
        private _snackbar: MatSnackBar,
        private _router: Router,
        private _domSanitizer: DomSanitizer,
        private _authService: AuthenticationService,
    ) { }

    ngOnInit() {
        this.isLoadingContent = true;
        this.paymentPlanOption = this._authService.getPaymentMethod();
        this._contractPlanId = this._authService.getContractPlanId();
        if (!this._contractPlanId) {
            this.errorRetrievingContract();
        }

        this._contractClient.contract_GetContractInformation(this._contractPlanId)
            .pipe(
                take(1),
                takeUntil(this._destroy$)
            )
            .subscribe(
                (result) => {
                    this.isLoadingContent = false;
                    this.contractData = result;
                    this.contractSectionHeaders = this.getContractSectionItems(this.contractData.contract, ContractSectionTypeEnum.Header);
                    this.contractSectionBodies = this.getContractSectionItems(this.contractData.contract, ContractSectionTypeEnum.Body);
                },
                (err) => {
                    this.contractSectionHeaders = [];
                    this.contractSectionBodies = [];
                    this.isLoadingContent = false;
                    this.errorRetrievingContract();
                }
            );
    }

    ngOnDestroy() {
        this._destroy$.next(true);
    }

    errorRetrievingContract(): void {
        this.errorMessage = 'There was an issue while retrieving your contract. Please ensure that if you have copied a url you have copied it correctly. If your quote has changed since you received the contract link then please have the office send you a new link.';
    }

    openSignatureWindow(type: SignatureType, contractSectionItemId?: number) {
        const data: SignatureDialogData = { type: type };
        let config: { [x: string]: string | number } = {};
        config['maxWidth'] = '100vw';
        if (type == 'full') {
            config['width'] = '90vw';
        } else if (type == 'initials') {
            config['width'] = '500px';
        }
        this._matDialog
            .open(SignatureDialogComponent, { data: data, disableClose: true, ...config })
            .afterClosed()
            .pipe(take(1))
            .subscribe((result: SignatureDialogResult) => {
                if (result) {
                    //split of base64 data from mime stuff
                    const imgData = result.value;
                    if (result.type == 'full') {
                        this.contractFinalSignature = imgData;
                    } else {
                        this.paragraphSignatures[contractSectionItemId] = imgData;
                    }
                }
            });
    }

    getContractSectionBody(contract: ContractDto, section: ContractSectionTypeEnum) {
        if (contract.contractSections) {
            const content = contract.contractSections
                .find((s) => s.contractSectionType == section)
                .body;
            return this.parseItemBody(content);
        }
        return null;
    }

    getContractSectionItems(contract: ContractDto, section: ContractSectionTypeEnum) {
        return (
            contract.contractSections &&
            contract.contractSections
                .find((s) => s.contractSectionType == section)
                .contractSectionItems.filter((a) => a.isActive)
                .sort((a, b) => a.order - b.order)
        );
    }

    parseItemBody(body: string) {
        return this._domSanitizer.bypassSecurityTrustHtml(body.replace('\\n', '<br>').replace(/(\r\n|\n|\r)/gm, '<br>'));
    }

    get PaymentTotalAmount(): number {
        let total: number = null;
        if (this.contractData && this.contractData.patientContractPlan) {
            total = 0;
            const cp = this.contractData.patientContractPlan;
            total += cp.recordsFee;
            total += cp.treatmentFee;
            cp.fees.forEach((fee) => (total += fee.amount));
            cp.discounts.forEach((discount) => (total -= discount.amount));
        }
        return total;
    }

    get PatientResponsibleAmount(): number {
        let responsiblePortion: number = this.PaymentTotalAmount;
        if (responsiblePortion) {
            const cp = this.contractData.patientContractPlan;
            cp.insurances.forEach((insurance) => (responsiblePortion -= insurance.amount));
        }
        return responsiblePortion;
    }

    get PaymentOptionFinal(): number {
        let total = this.PatientResponsibleAmount;
        if (total) {
            total -= this.contractData.patientContractPlan.amountDown;
            //Accurately round to 2 decimal places (borrowed from https://stackoverflow.com/a/11832950)
            return Math.round((total / this.contractData.patientContractPlan.paymentTermsMonths + Number.EPSILON) * 100) / 100;
        }
    }

    get ContractRequiredParagraphSignatures(): number {
        if (this.contractData && this.contractData.contract && this.contractData.contract.contractSections) {
            return this.contractData.contract.contractSections
                .find((s) => s.contractSectionType == ContractSectionTypeEnum.Body)
                .contractSectionItems.filter((si) => si.isSignature).length;
        }
        return 0;
    }

    finalPaymentDue(): Date {
        const startDate = moment(this.contractData.patientContractPlan.startDate);
        return startDate.add(this.contractData.patientContractPlan.paymentTermsMonths, 'months').toDate();
    }

    get FancyDate(): string {
        const now = moment();
        return now.format('dddd, D MMMM YYYY');
    }

    submitContract() {
        if (this.ContractRequiredParagraphSignatures != Object.keys(this.paragraphSignatures).length || this.contractFinalSignature == null) {
            this._snackbar.open('Please sign all required items.', 'OK', { duration: 2000 });
            return;
        }

        this.isGeneratingContract = true;

        setTimeout(() => {
            let content = this.contractContent.nativeElement;

            let opt = {
                margin: [0.5, 0.5, 0.5, 0.5],
                filename: 'Contract.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 1, scrollY: 0, useCORS: true, dpi: 192, letterRendering: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                pagebreak: { mode: "css" },
            };

            let pdfWorker = html2pdf().from(content).set(opt).toPdf().get('pdf').then((pdf) => {
                var totalPages = pdf.internal.getNumberOfPages();
                var pgHt = pdf.internal.pageSize.getHeight();

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(150);

                    const today = moment();
                    let publishedDt: any = today.format('MM/DD/YYYY, hh:mm a');
                    let footerText: any = `Page ${i} of ${totalPages}   ${publishedDt}`
                    pdf.text(footerText, 0.25, pgHt - 0.25);
                }
            });

            pdfWorker.outputPdf('blob').then((blob: Blob) => {
                const data = new Blob([blob], { type: blob.type })

                const file: FileParameter = {
                    data,
                    fileName: 'Contract.pdf',
                };

                this.isLoadingContent = true;
                this._contractClient
                    .contract_PostContract(
                        this._contractPlanId,
                        null,
                        file,
                        StorageContentTypeEnum.Contract)
                    .pipe(
                        take(1),
                        takeUntil(this._destroy$)
                    )
                    .subscribe(
                        (result) => {
                            this.isLoadingContent = false;
                            this.isGeneratingContract = false;
                            this._router.navigate(['/contracts', 'thankyou']);
                        },
                        (err: ErrorDetails) => {
                            this.isLoadingContent = false;
                            this.isGeneratingContract = false;
                            this._snackbar.open(`Error: ${err.message}`, 'OK');
                        }
                    );
            });
        }, 0);
    }
}
