<div class="content-wrapper evolution-scrollbar">
    <div class="container content">
        <div class="col">
            <div class="inner-content mat-elevation-z6">
                <ng-container *ngIf="contractData && isLoadingCount == 0; else isLoadingTemplate">
                    <div *ngIf="contractData.patientContractPlan as contractPlan">
                        <div class="contract-wrapper">
                            <ng-container *ngIf="contractData.quote.logo">
                                <div style="text-align: center; margin-bottom: 10px; cursor: pointer">
                                    <img [src]="contractData.quote.logo"
                                         style="max-width: 120px; max-height: 120px;">
                                </div>
                            </ng-container>

                            <div class="practice-address">
                                <div class="address-line"
                                     *ngFor="let item of sectionHeaders"
                                     [innerHTML]="item.body"></div>
                            </div>

                            <section>
                                <h2>Patient</h2>
                                <div class="patient-details">
                                    <div class="name">
                                        {{contractData.patientContact.firstName}} {{contractData.patientContact.lastName}}
                                    </div>
                                    <div class="birthdate">{{today | date: 'MM/dd/yyyy'}}</div>
                                </div>
                            </section>

                            <section *ngIf="contractData.signatureContact">
                                <h2>Responsible Party</h2>
                                <div class="responsible-details">
                                    <div class="detail-line">
                                        {{contractData.signatureContact.firstName}}
                                        {{contractData.signatureContact.lastName}}
                                    </div>
                                    <ng-container *ngIf="contractData.signatureContact.address">
                                        <div class="detail-line">{{contractData.signatureContact.address.addressLine1}}</div>
                                        <div class="detail-line"
                                             *ngIf="contractData.signatureContact.address.addressLine2">
                                            {{contractData.signatureContact.address.addressLine2}}
                                        </div>
                                        <div class="detail-line">
                                            {{contractData.signatureContact.address.city}},
                                            {{contractData.signatureContact.address.state}} {{contractData.signatureContact.address.zip}}
                                        </div>
                                    </ng-container>
                                </div>
                            </section>

                            <section>
                                <h2>Contract</h2>

                                <p class="contract-paragraph"
                                   [innerHTML]="sectionBody">
                                </p>
                            </section>
                        </div>

                        <form #quoteForm="ngForm">
                            <h1>
                                {{contractPlan.treatmentType}} - {{contractPlan.treatmentLengthMonths}} Months
                            </h1>
                            <h3>
                                Configure Your Desired Payment Plan
                            </h3>
                            <div class="itemization-wrapper">
                                <mat-list class="fee-list">
                                    <mat-list-item>
                                        <span matLine class="fee-amount">{{contractPlan.recordsFee}}</span>
                                        <span matLine class="fee-label">Records Fee</span>
                                    </mat-list-item>
                                    <mat-list-item>
                                        <span matLine class="fee-amount">{{contractPlan.treatmentFee}}</span>
                                        <span matLine class="fee-label">Treatment Fee</span>
                                    </mat-list-item>

                                    <h3 matSubheader *ngIf="contractPlan.fees && contractPlan.fees.length > 0">Additional Fees</h3>
                                    <mat-list-item *ngFor="let fee of contractPlan.fees">
                                        <span matLine class="fee-amount">{{fee.amount}}</span>
                                        <span matLine class="fee-label">{{fee.name}}</span>
                                    </mat-list-item>

                                    <h3 matSubheader *ngIf="contractPlan.discounts && contractPlan.discounts.length > 0">Discounts</h3>
                                    <mat-list-item *ngFor="let discount of contractPlan.discounts">
                                        <span matLine class="fee-amount">-{{discount.amount}}</span>
                                        <span matLine class="fee-label">{{discount.name}}</span>
                                    </mat-list-item>

                                    <mat-divider></mat-divider>

                                    <mat-list-item>
                                        <span matLine class="fee-amount">{{this.PaymentTotalAmount}}</span>
                                        <span matLine class="fee-label">Total</span>
                                    </mat-list-item>
                                    <mat-list-item *ngFor="let insurance of contractPlan.insurances">
                                        <span matLine class="fee-amount">-{{insurance.amount}}</span>
                                        <span matLine class="fee-label">{{insurance.name}}</span>
                                    </mat-list-item>

                                    <mat-divider></mat-divider>

                                    <mat-list-item>
                                        <span matLine class="fee-amount">{{this.PatientResponsibleAmount}}</span>
                                        <span matLine class="fee-label">Patient Responsible Portion</span>
                                    </mat-list-item>
                                </mat-list>
                            </div>

                            <div class="payment-method-wrapper">
                                <h2>Payment Method Options</h2>
                                <div class="payment-method-option-wrapper">
                                    <ng-container *ngIf="contractData.quote.allowPayInFull && contractData.quote.allowPayInInstallments">
                                        <span class="payment-option" [class.selected]="!paymentMethodToggle.checked"
                                              (click)="paymentMethodToggle.checked = false">Pay in Full</span>
                                        <mat-slide-toggle class="evolution"
                                                          color="primary"
                                                          #paymentMethodToggle
                                                          [(ngModel)]="isPaymentPlanOption"
                                                          name="isPaymentPlanOption">
                                        </mat-slide-toggle>
                                        <span class="payment-option" [class.selected]="paymentMethodToggle.checked"
                                              (click)="paymentMethodToggle.checked = true">Payment Installments</span>
                                    </ng-container>
                                    <ng-container *ngIf="!contractData.quote.allowPayInFull || !contractData.quote.allowPayInInstallments">
                                        <span class="payment-option selected"
                                              *ngIf="contractData.quote.allowPayInInstallments">Payment Installments</span>
                                        <span class="payment-option selected"
                                              *ngIf="contractData.quote.allowPayInFull">Pay in Full</span>
                                    </ng-container>
                                    <!--<span class="payment-option" [class.selected]="!paymentMethodToggle.checked"
                              (click)="paymentMethodToggle.checked = false">Pay in Full</span>
                        <mat-slide-toggle class="evolution" color="primary" #paymentMethodToggle [(ngModel)]="isPaymentPlanOption" name="isPaymentPlanOption">
                        </mat-slide-toggle>
                        <span class="payment-option" [class.selected]="paymentMethodToggle.checked"
                              (click)="paymentMethodToggle.checked = true">Payment Installments</span>-->
                                </div>

                                <ng-container *ngIf="isPaymentPlanOption">
                                    <mat-card @fadeInOut>

                                        <h3>Down Payment</h3>
                                        <small *ngIf="contractData.quote.isActiveSlideBars">
                                            Use slider or input to configure your desired down payment
                                        </small>
                                        <div>
                                            <span class="payment-amount">
                                                <input type="number"
                                                       #amountDownInput="ngModel"
                                                       [(ngModel)]="contractPlan.amountDown"
                                                       required
                                                       name="amountDown"
                                                       [disabled]="!contractData.quote.isActiveSlideBars"
                                                       [validateMinMax]="[contractData.eQuoteMinimumDownAmount,this.PatientResponsibleAmount]">
                                            </span>
                                        </div>
                                        <div *ngIf="amountDownInput.errors">
                                            <mat-error *ngIf="amountDownInput.errors.min">
                                                Please specify an amount more than {{ contractData.eQuoteMinimumDownAmount || 0 }}
                                            </mat-error>
                                            <mat-error *ngIf="amountDownInput.errors.max">
                                                Please specify an amount less than {{ this.PatientResponsibleAmount }}
                                            </mat-error>
                                        </div>
                                        <mat-slider class="payment-slider"
                                                    color="primary"
                                                    [min]="contractData.eQuoteMinimumDownAmount"
                                                    [max]="this.PatientResponsibleAmount"
                                                    [value]="contractPlan.amountDown"
                                                    [step]="10"
                                                    thumbLabel
                                                    *ngIf="contractData.quote.isActiveSlideBars"
                                                    (change)="contractPlan.amountDown = $event.value">
                                        </mat-slider>

                                        <h3>Months of Repayment</h3>
                                        <small *ngIf="contractData.quote.isActiveSlideBars">
                                            Use slider or input to configure the number of months you'd like for your payment plan
                                        </small>
                                        <div>
                                            <span class="payment-amount">
                                                <input type="number"
                                                       #paymentTermMonthsInput="ngModel"
                                                       [(ngModel)]="contractPlan.paymentTermsMonths"
                                                       name="paymentTermMonths"
                                                       required
                                                       [disabled]="!contractData.quote.isActiveSlideBars"
                                                       [validateMinMax]="[1,contractData.eQuoteMaxMonths]">
                                            </span>
                                        </div>
                                        <div *ngIf="paymentTermMonthsInput.errors">
                                            <mat-error *ngIf="paymentTermMonthsInput.errors.min">
                                                Please specify a number of months more than zero
                                            </mat-error>
                                            <mat-error *ngIf="paymentTermMonthsInput.errors.max">
                                                Please specify a number of months fewer or equal to {{ contractData.eQuoteMaxMonths || 20 }}
                                            </mat-error>
                                        </div>
                                        <mat-slider class="payment-slider"
                                                    color="primary"
                                                    [min]="1"
                                                    [max]="contractData.eQuoteMaxMonths || 20"
                                                    [value]="contractPlan.paymentTermsMonths"
                                                    thumbLabel
                                                    *ngIf="contractData.quote.isActiveSlideBars"
                                                    (change)="contractPlan.paymentTermsMonths = $event.value">
                                        </mat-slider>

                                    </mat-card>

                                    <div style="padding: 10px" @fadeInOut>
                                        <h3>Per Month Amount</h3>
                                        <span class="payment-amount">{{this.PaymentOptionFinal | currency}}</span>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="!isPaymentPlanOption">
                                    <div style="padding: 1.5em 0">
                                        <h3>Amount Due</h3>
                                        <em>-{{this.PaidInFullDiscount}} Bookkeeping discount</em> <br>
                                        <span class="payment-amount">{{this.PaidInFullFinal| currency}}</span>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="buttons-wrapper">
                                <button color="primary" mat-raised-button (click)="this.submitQuoteAcceptance(true)"
                                        [disabled]="quoteForm.invalid">
                                    Yes, I Want To Proceed
                                </button>
                                <button color="warn" mat-raised-button (click)="this.submitQuoteAcceptance(false)"
                                        [disabled]="quoteForm.invalid">
                                    I Need More Time To Consider
                                </button>
                            </div>
                        </form>
                    </div>

                </ng-container>

                <mat-error *ngIf="this.errorMessage">{{this.errorMessage}}</mat-error>

                <ng-template #isLoadingTemplate>
                    <mat-progress-bar mode="indeterminate" *ngIf="isLoadingCount > 0"></mat-progress-bar>
                </ng-template>
            </div>
        </div>
    </div>
</div>
