import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';
import { PatientConfirmComponent } from './patient-confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
	declarations: [PatientConfirmComponent],
	exports: [PatientConfirmComponent],
	imports: [
		CommonModule,
		FormsModule,
		MomentDateModule,
		MatDialogModule,
		MatProgressBarModule
	]
})
export class PatientConfirmModule { }
