import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { PatientCasePresentationComponent } from './patient-case-presentation.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
	declarations: [PatientCasePresentationComponent],
	exports: [PatientCasePresentationComponent],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		FontAwesomeModule,
		MatSelectModule,
		MatFormFieldModule
	]
})
export class PatientCasePresentationModule { }
