import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'orderPipe'
})
export class OrderPipe implements PipeTransform {
  transform(array: any[], sortBy: string, isAsc?: boolean, retrigger?: boolean): any[] {
    return orderBy(array, [sortBy], [isAsc]);
  }
}
