<div class="component-container" style="max-width: 768px; margin: auto;">
  <div class="container-fluid">
    <mat-progress-bar mode="indeterminate" *ngIf="(insuranceCompaniesIsLoading$ | async) || isWorking"></mat-progress-bar>
    <ng-container *ngIf="(appointmentDetail$ | async) || appointmentDetail">
      <div class="row justify-content-center">
        <div class="col-md-12 col-sm-12 col-xs-12  bottomspace">
            <div class="section-container">
                <div class="top-banner">
                    <ng-container *ngIf="(logoLocationUrl$ | async) as logoLocationUrl">
                        <img [src]="logoLocationUrl"
                             class="logo-container"
                             *ngIf="logoLocationUrl" />
                    </ng-container>
                </div>
                <div class="detail-info-section">
                    <div class="patient-name">{{appointmentDetail?.patientFirstName}} {{appointmentDetail?.patientLastName}}</div>
                    <div class="info-text">You are scheduled</div>
                    <div class="schedule-info">
                        {{appointmentDetail?.startTime | momentDate: 'MMMM dd' : appointmentDetail?.locationTimeZone}}<br>
                        at<br>
                        {{appointmentDetail?.startTime | momentDate: 'hh:mm a' : appointmentDetail?.locationTimeZone}}
                    </div>
                    <div>{{appointmentDetail?.locationAddress}}</div>
                </div>
                <ng-container *ngIf="!isFinished && !isCheckInsurance && !showCoInsurance">
                    <div class="insurance-check-label">Would you like us to check your insurance coverage?</div>
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <button mat-button
                                        class="cancel-btn"
                                        (click)="navigateTo('confirmation')">
                                    No
                                </button>
                            </div>
                            <div class="col-6">
                                <button mat-button
                                        class="confirm-btn new-patient-url-button"
                                        (click)="navigateTo('insurance')">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isFinished && confirmSecondaryInsurance && !showCoInsurance">
                    <div class="insurance-check-label">Do you have secondary insurance?</div>
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <button mat-button
                                        class="cancel-btn"
                                        (click)="navigateTo('confirmation')">
                                    No
                                </button>
                            </div>
                            <div class="col-6">
                                <button mat-button
                                        class="confirm-btn new-patient-url-button"
                                        (click)="navigateTo('coinsurance')">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isFinished && isSomethingMissing">
                    <div class="insurance-check-label"
                         style="margin-bottom: 0px;">
                        Something is missing!
                    </div>
                    <div class="insurance-check-label-sub">
                        Subscriber name?<br />
                        Insurance info?
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <button mat-button
                                        class="cancel-btn"
                                        (click)="navigateTo('confirmation')">
                                    continue no insurance
                                </button>
                            </div>
                            <div class="col-6">
                                <button mat-button
                                        class="confirm-btn new-patient-url-button"
                                        (click)="isSomethingMissing = false">
                                    oops let me go back and correct
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isFinished">
                    <div class="detail-bottom-note-section">
                        <ng-container *ngIf="(footerText$ | async) as footerText">
                            <div [innerHTML]="footerText"></div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>

        <ng-container *ngIf="!isFinished && isCheckInsurance && !isSomethingMissing && !confirmSecondaryInsurance">
          <div class="col-md-12 col-sm-12 col-xs-12 bottomspace">
            <mat-progress-bar mode="indeterminate" *ngIf="isSavingInsurance"></mat-progress-bar>
            <form [formGroup]="insuranceInformation"
                  novalidate
                  (ngSubmit)="saveInsurance()">
              <div class="section-container colored-bg">
                <div class="input-full-width" style="display: flex; flex-direction: column;">
                  <mat-label class="input-label">Insurance Subscriber</mat-label>
                  <mat-radio-group (change)="insuranceTypeChanged(insuranceInformation, $event.value)">
                    <mat-radio-button [value]="'patient'"
                                      class="insurace-type">
                      {{appointmentDetail.patientFirstName}} {{appointmentDetail.patientLastName}}
                    </mat-radio-button>
                    <mat-radio-button [value]="'responsible'"
                                      class="insurace-type"
                                      *ngIf="!appointmentDetail.isPatientResponsible">
                      {{appointmentDetail.contactFirstName}} {{appointmentDetail.contactLastName}}
                    </mat-radio-button>
                    <mat-radio-button [value]="'other'"
                                      class="insurace-type">
                      Other
                    </mat-radio-button>
                  </mat-radio-group>
                </div>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Subscriber first name</mat-label>
                  <input matInput
                         type="text"
                         formControlName="firstName"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Subscriber last name</mat-label>
                  <input matInput
                         type="text"
                         formControlName="lastName"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Relationship to patient</mat-label>
                  <mat-select formControlName="relationshipType"
                              [disableOptionCentering]="true">
                    <mat-option *ngFor="let relationshipType of relationshipTypes"
                                [value]="relationshipType">
                      {{relationshipType}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Subscriber date of birth</mat-label>
                  <input matInput
                         [matDatepicker]="insuranceBirthdate"
                         placeholder="MM/DD/YYYY"
                         autocomplete="off"
                         [max]="today"
                         disabled
                         formControlName="dob"
                         (dateChange)="birthDateChanged(insuranceInformation, $event.value)">
                  <mat-datepicker-toggle matSuffix
                                         [for]="insuranceBirthdate">
                  </mat-datepicker-toggle>
                  <mat-datepicker #insuranceBirthdate
                                  disabled="false"
                                  [startAt]="today">
                  </mat-datepicker>
                </mat-form-field>

                <div style="display: flex; align-items: center;">
                  <button mat-icon-button
                    type='button'
                    color="accent"
                    matTooltip="Upload picture of insurance card"
                    ngfSelect
                    multiple="1"
                    (filesChange)="insuranceFileChanged($event)"
                    [(files)]="insuranceFiles">
                    <mat-icon>attach_file</mat-icon>
                  </button>

                  <mat-label class="input-label" style="margin-left: 5px; margin-bottom: 0;">Upload insurance card or enter manually below</mat-label>
                </div>

                

                <mat-list dense *ngIf="insuranceInformation.get('files').value">
                    <mat-list-item *ngFor="let fileAttachment of getAttachedInsuranceFiles(); let i = index">
                        {{fileAttachment.name}}
                        <mat-icon color="warn"
                                    class="remove-attachment"
                                    (click)="removeAttachedInsuranceFile(i)"
                                    matTooltip="Remove Attachment">
                            close
                        </mat-icon>
                    </mat-list-item>
                </mat-list>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Insurance Company</mat-label>
                  <input type="text"
                             matInput
                             #InsuranceInput
                             [matAutocomplete]="autoInsurance"
                             class="insurance-input"
                             (keyup)="searchInsurance($event)"
                             [(ngModel)] = "selectedInsuranceInfo"
                             [ngModelOptions]="{standalone: true}"
                             >
                  
                          <mat-autocomplete #autoInsurance="matAutocomplete"
                              (optionSelected)="selectInsuranceCompany(insuranceInformation, $event.option.value)">
                          
                              <mat-option *ngFor="let insuranceCompany of filteredInsuranceCompanies" [value]="insuranceCompany">
                                  {{insuranceCompany.name}}
                              </mat-option>
                          </mat-autocomplete>
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Insurance name</mat-label>
                  <input matInput
                         type="text"
                         formControlName="insuranceName"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Insurance payor ID (if known)</mat-label>
                  <input matInput
                         type="text"
                         formControlName="insurancePayorId"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Member ID</mat-label>
                  <input matInput
                         type="text"
                         formControlName="memberId"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Group ID</mat-label>
                  <input matInput
                         type="text"
                         formControlName="groupId"
                         autocomplete="off">
                </mat-form-field>

                <div class="insurance-note">
                  Not all insurances provide electronic eligibility.  If yours does, we will have that information available for you when you arrive.
                </div>

                <button mat-button
                        [disabled]="this.showCoInsurance || isSavingInsurance"
                        class="appintment-btn new-patient-url-button"
                        type="submit">
                  <ng-container *ngIf="!this.showCoInsurance">
                    submit insurance informaton
                  </ng-container>
                  <ng-container *ngIf="this.showCoInsurance">
                    Submitted
                  </ng-container>
                </button>
              </div>
            </form>
          </div>
        </ng-container>

        <ng-container *ngIf="!isFinished && showCoInsurance && !isSomethingMissing">
          <div class="col-md-12 col-sm-12 col-xs-12 bottomspace">
            <mat-progress-bar mode="indeterminate" *ngIf="isSavingCoInsurance"></mat-progress-bar>
            <form [formGroup]="coInsuranceInformation"
                  novalidate
                  (ngSubmit)="saveCoInsurance()">
              <div class="section-container colored-bg">
                <div class="input-full-width" style="display: flex; flex-direction: column;">
                  <mat-label class="input-label">Insurance Subscriber</mat-label>
                  <mat-radio-group (change)="insuranceTypeChanged(coInsuranceInformation, $event.value)">
                    <mat-radio-button [value]="'patient'"
                                      class="insurace-type">
                      {{appointmentDetail.patientFirstName}} {{appointmentDetail.patientLastName}}
                    </mat-radio-button>
                    <mat-radio-button [value]="'responsible'"
                                      class="insurace-type"
                                      *ngIf="!appointmentDetail.isPatientResponsible">
                      {{appointmentDetail.contactFirstName}} {{appointmentDetail.contactLastName}}
                    </mat-radio-button>
                    <mat-radio-button [value]="'other'"
                                      class="insurace-type">
                      Other
                    </mat-radio-button>
                  </mat-radio-group>
                </div>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Subscriber first name</mat-label>
                  <input matInput
                         type="text"
                         formControlName="firstName"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Subscriber last name</mat-label>
                  <input matInput
                         type="text"
                         formControlName="lastName"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Relationship to patient</mat-label>
                  <mat-select formControlName="relationshipType"
                              [disableOptionCentering]="true">
                    <mat-option *ngFor="let relationshipType of relationshipTypes"
                                [value]="relationshipType">
                      {{relationshipType}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Subscriber date of birth</mat-label>
                  <input matInput
                         [matDatepicker]="insuranceBirthdate"
                         placeholder="MM/DD/YYYY"
                         autocomplete="off"
                         [max]="today"
                         disabled
                         formControlName="dob"
                         (dateChange)="birthDateChanged(coInsuranceInformation, $event.value)">
                  <mat-datepicker-toggle matSuffix
                                         [for]="insuranceBirthdate">
                  </mat-datepicker-toggle>
                  <mat-datepicker #insuranceBirthdate
                                  disabled="false"
                                  [startAt]="today">
                  </mat-datepicker>
                </mat-form-field>

                <div style="display: flex; align-items: center;">
                  <button mat-icon-button
                        type='button'
                        color="accent"
                        matTooltip="Upload picture of insurance card"
                        ngfSelect
                        multiple="1"
                        (filesChange)="coInsuranceFileChanged($event)"
                        [(files)]="coInsuranceFiles">
                        <mat-icon>attach_file</mat-icon>
                    </button>

                    <mat-label class="input-label" style="margin-left: 5px; margin-bottom: 0;">Upload insurance card or enter manually below</mat-label>
                </div>
                  

                <mat-list dense *ngIf="coInsuranceInformation.get('files').value">
                    <mat-list-item *ngFor="let fileAttachment of getAttachedCoInsuranceFiles(); let i = index">
                        {{fileAttachment.name}}
                        <mat-icon color="warn"
                                    class="remove-attachment"
                                    (click)="removeAttachedCoInsuranceFile(i)"
                                    matTooltip="Remove Attachment">
                            close
                        </mat-icon>
                    </mat-list-item>
                </mat-list>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Insurance Company</mat-label>
                  <input type="text"
                             matInput
                             #InsuranceInput
                             [matAutocomplete]="autoInsurance"
                             class="insurance-input"
                             (keyup)="searchInsurance($event)"
                             [(ngModel)] = "selectedInsurance"
                             [ngModelOptions]="{standalone: true}"
                             >
                  
                          <mat-autocomplete #autoInsurance="matAutocomplete"
                              (optionSelected)="selectInsuranceCompany(coInsuranceInformation, $event.option.value)">
                          
                              <mat-option *ngFor="let insuranceCompany of filteredInsuranceCompanies" [value]="insuranceCompany">
                                  {{insuranceCompany.name}}
                              </mat-option>
                          </mat-autocomplete>
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Insurance name</mat-label>
                  <input matInput
                         type="text"
                         formControlName="insuranceName"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Insurance payor ID (if known)</mat-label>
                  <input matInput
                         type="text"
                         formControlName="insurancePayorId"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Member ID</mat-label>
                  <input matInput
                         type="text"
                         formControlName="memberId"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field class="input-full-width">
                  <mat-label class="input-label">Group ID</mat-label>
                  <input matInput
                         type="text"
                         formControlName="groupId"
                         autocomplete="off">
                </mat-form-field>

                <button mat-button
                        [disabled]="this.isSubmittedCoInsurance || isSavingCoInsurance"
                        class="appintment-btn new-patient-url-button"
                        type="submit">
                  <ng-container *ngIf="!this.isSubmittedCoInsurance">
                    submit insurance informaton
                  </ng-container>
                  <ng-container *ngIf="this.isSubmittedCoInsurance">
                    Submitted
                  </ng-container>
                </button>

                <button mat-button
                        style="margin-top: 20px;"
                        [disabled]="isSavingCoInsurance"
                        class="appintment-btn new-patient-url-button"
                        (click)="navigateTo('confirmation')">
                  finished!
                </button>
              </div>
            </form>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>