import { createAction, props } from "@ngrx/store";
import { AppointmentStoreEntity } from './state';

//LOAD LOCATION
export const LoadRequest = createAction(
  '[Appointment] Load Request',
  props<{ locationId?: number; }>(),
);

export const LoadSuccess = createAction(
  '[Appointment] Load Success',
  props<{ appointments: AppointmentStoreEntity[]; }>()
);

export const LoadFailure = createAction(
  '[Appointment] Load Failure',
  props<{ error: any; }>()
);

//SELECT
export const SelectRequest = createAction(
  '[Appointment] Select Request',
  props<{ id: number; }>()
);

export const SelectSuccess = createAction(
  '[Appointment] Select Success',
  props<{ appointment: AppointmentStoreEntity; }>()
);

export const SelectFailure = createAction(
  '[Appointment] Select Failure',
  props<{ error: any; }>()
);

//SELECT BY DATE
export const SelectByDateRequest = createAction(
  '[Appointment] Select By Date Request',
  props<{ id: number; }>()
);

export const SelectByDateSuccess = createAction(
  '[Appointment] Select By Date Success',
  props<{ appointments: AppointmentStoreEntity[]; }>()
);

export const SelectByDateFailure = createAction(
  '[Appointment] Select By Date Failure',
  props<{ error: any; }>()
);
