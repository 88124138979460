import { Component, OnInit } from '@angular/core';
import { AuthStoreSelectors, InsuranceCompanyStoreActions, InsuranceCompanyStoreEntity, InsuranceCompanyStoreSelectors, RootStoreState } from '../../root-store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-marketing-details',
  templateUrl: './marketing-details.component.html',
  styleUrls: ['./marketing-details.component.css']
})
export class MarketingDetailsComponent implements OnInit {

  logoLocationUrl$ = this._store$.select(AuthStoreSelectors.getLogoLocationUrl);
  footerText$ = this._store$.select(AuthStoreSelectors.getFooterText);
  marketingDetails: any;

  constructor(
    private _store$: Store<RootStoreState.State>,
  ) { }

  ngOnInit(): void {
    this.marketingDetails = JSON.parse(sessionStorage.getItem('marketingdetail'));    
  }

}
