import { Injectable } from '@angular/core';
import { defaultTheme, Theme } from '../../models/theme/ThemeColor';

@Injectable({ providedIn: 'root' })
export class ThemeService  {
    private active: Theme = defaultTheme;

    constructor() { }

    setTheme(theme: Theme): void {
        this.active = theme;

        Object.keys(this.active.properties).forEach(property => {
            document.documentElement.style.setProperty(
                property,
                this.active.properties[property]
            );
        });
    }
}
