import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DigitalSignatureModule } from '../digital-signature/digital-signature.module';
import { SignatureDialogComponent } from './signature-dialog.component';


@NgModule({
    imports: [
        DigitalSignatureModule,
        MatDialogModule,
        MatButtonModule,
    ],
    exports: [SignatureDialogComponent],
    declarations: [SignatureDialogComponent],
    providers: [],
})
export class SignatureDialogModule { }
