import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule } from '@angular/forms';
import { PatientCheckInComponent } from './patient-check-in.component';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';

@NgModule({
	declarations: [PatientCheckInComponent],
	exports: [PatientCheckInComponent],
	imports: [
		CommonModule,
		MatSelectModule,
		FormsModule,
		MomentDateModule,
		MatProgressBarModule
	]
})
export class PatientCheckInModule { }
