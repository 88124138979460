import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { LocationsStoreSelectors, LocationStoreEntity, RootStoreState } from 'src/app/root-store';
import { ContractClient, ContractInformationDto, QuoteDto, QuoteSectionItemDto, QuoteSectionTypeEnum } from 'src/app/shared/services/api.service';
import { AuthenticationService } from '../../shared/services/auth';

@Component({
    selector: 'app-quote',
    templateUrl: './quote.component.html',
    styleUrls: ['../contracts.component.scss', './quote.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [style({ opacity: 0 }), animate('250ms ease-in', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('250ms ease-in', style({ opacity: 0 }))]),
        ]),
    ],
})
export class QuoteComponent implements OnInit, OnDestroy {
    isLoadingCount: number = 0;
    errorMessage: string;
    _contractPlanId: number;
    contractData: ContractInformationDto;
    get contractMaxMonths(): number {
        return (this.contractData && this.contractData.maxPaymentMonths) || 20;
    }
    isPaymentPlanOption: boolean = true;
    location$: Observable<LocationStoreEntity>;
    sectionBody: SafeHtml;
    sectionHeaders: QuoteSectionItemDto[];
    today: Date = new Date();
    private _destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private _contractClient: ContractClient,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _store$: Store<RootStoreState.State>,
        private _domSanitizer: DomSanitizer,
        private _authService: AuthenticationService,
    ) { }

    ngOnInit() {
        this.isLoadingCount += 1;
        this._contractPlanId = this._authService.getContractPlanId();
        if (!this._contractPlanId) {
            this.errorRetrievingQuote();
        }

        this._contractClient.contract_GetContractInformation(this._contractPlanId, true)
            .pipe(
                take(1),
                takeUntil(this._destroy$)
            )
            .subscribe(
                (result) => {
                    this.isLoadingCount -= 1;
                    this.contractData = result;

                    if (this.contractData && this.contractData.quote) {
                        this.sectionBody = this.getQuoteSectionBody(this.contractData.quote);
                        this.sectionHeaders = this.getQuoteSectionHeaderItems(this.contractData.quote);

                        if (this.contractData.quote.allowPayInInstallments) {
                            this.isPaymentPlanOption = true
                        }
                        else if (this.contractData.quote.allowPayInFull) {
                            this.isPaymentPlanOption = false;
                        }
                    }

                    this.location$ = this._store$
                        .select(LocationsStoreSelectors.selectLocationById(this.contractData.locationId))
                        .pipe(filter((location) => !!location));
                },
                (err) => {
                    this.isLoadingCount -= 1;
                    this.errorRetrievingQuote();
                }
            );
    }

    ngOnDestroy() {
        this._destroy$.next(true);
    }

    errorRetrievingQuote(): void {
        this.errorMessage = 'There was an issue while retrieving your quote. If you have copied and pasted a URL ensure it was copied correctly. Otherwise please contact the office to receive a new URL for your quote.';
    }

    get PaymentTotalAmount(): number {
        let total: number = null;
        if (this.contractData && this.contractData.patientContractPlan) {
            total = 0;
            const cp = this.contractData.patientContractPlan;
            total += cp.recordsFee;
            total += cp.treatmentFee;
            cp.fees.forEach((fee) => (total += fee.amount));
            cp.discounts.forEach((discount) => (total -= discount.amount));
        }
        return total;
    }

    get PatientResponsibleAmount(): number {
        let responsiblePortion: number = this.PaymentTotalAmount;
        if (responsiblePortion) {
            const cp = this.contractData.patientContractPlan;
            cp.insurances.forEach((insurance) => (responsiblePortion -= insurance.amount));
        }
        return responsiblePortion;
    }

    get PaymentOptionFinal(): number {
        let total = this.PatientResponsibleAmount;
        if (total) {
            total -= this.contractData.patientContractPlan.amountDown;
            //Accurately round to 2 decimal places (borrowed from https://stackoverflow.com/a/11832950)
            return Math.round((total / this.contractData.patientContractPlan.paymentTermsMonths + Number.EPSILON) * 100) / 100;
        }
    }

    get PaidInFullDiscount(): number {
        if (this.contractData) return Math.round(this.PatientResponsibleAmount * (this.contractData.patientContractPlan.bookkeepingDiscount / 100));
    }

    get PaidInFullFinal(): number {
        return this.PatientResponsibleAmount - this.PaidInFullDiscount;
    }

    submitQuoteAcceptance(isAccepted: boolean) {
        this.isLoadingCount += 1;
        this._contractClient
            .contract_PutPatientContractPlan(
                this._contractPlanId,
                this.contractData.patientContractPlan.amountDown,
                this.contractData.patientContractPlan.paymentTermsMonths,
                isAccepted
            )
            .pipe(
                take(1),
                takeUntil(this._destroy$)
            )
            .subscribe((_) => {
                this.isLoadingCount -= 1;
                this._router.navigate(['/contracts', 'thankyou']);
            });
    }

    getQuoteSectionHeaderItems(quote: QuoteDto) {
        return (
            quote.quoteSections &&
            quote.quoteSections
                .find((s) => s.quoteSectionType == QuoteSectionTypeEnum.Header)
                .quoteSectionItems.filter((a) => a.isActive)
                .sort((a, b) => a.order - b.order)
        );
    }

    getQuoteSectionBody(quote: QuoteDto) {
        if (quote.quoteSections) {
            const content = quote.quoteSections
                .find((s) => s.quoteSectionType == QuoteSectionTypeEnum.Body)
                .body;
            return this.parseItemBody(content);
        }
        return null;
    }

    parseItemBody(body: string) {
        return this._domSanitizer.bypassSecurityTrustHtml(body.replace('\\n', '<br>').replace(/(\r\n|\n|\r)/gm, '<br>'));
    }
}
