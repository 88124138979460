import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/auth';
import { faFilePdf, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import html2pdf from 'html2pdf.js';
import { PatientClient, SecureMessageDto } from 'src/app/shared/services/api.service';
import * as moment from 'moment';
import {saveAs} from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-secure-email',
  templateUrl: './secure-email.component.html',
  styleUrls: ['./secure-email.component.css']
})
export class SecureEmailComponent implements OnInit {

  faFilePdf = faFilePdf;
  faPaperclip = faPaperclip;
  @ViewChild("content", { static: false }) content: ElementRef;
  patientId: any;
  messageId: any;
  maskedKey: any;
  securemsg:SecureMessageDto;
  secureMsgHtml:SafeHtml;
  isWorking:boolean = false;
  innerboxHt: any;

  constructor(
    private _authService: AuthenticationService,
    private _patientClient:PatientClient,
    private http: HttpClient,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.patientId = this._authService.getPatientId();
    this.messageId = this._authService.getMessageId();
    this.maskedKey = this._authService.getBrowserMaskedTenantKey();
    this.innerboxHt = window.innerHeight - 130;
    this.getPatientMessage();

  }

  getPatientMessage(){
    this.isWorking = true;
    this._patientClient.patient_GetPatientMessage(this.patientId, this.messageId, null, this.maskedKey).subscribe(resp => {
      this.securemsg = resp;
      this.secureMsgHtml = this._sanitizer.bypassSecurityTrustHtml(this.securemsg.htmlBody);

      this.isWorking = false;
    },
    err => {
      console.log(err);
      this.isWorking = false;
    })
  }

  generatePDF(){
    this.isWorking = true;
    setTimeout(() => {
      let content = this.content.nativeElement;
      var opt = {
        margin: [0.5, 0.5, 0.5, 0.5],
        filename: 'SECURE-EMAIL.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      };

      html2pdf()
        .from(content)
        .set(opt)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var pgHt = pdf.internal.pageSize.getHeight();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);

            const today = moment();
            let publishedDt: any = today.format('MM/DD/YYYY, hh:mm a');
            let footerText: any = `Page ${i} of ${totalPages}   ${publishedDt}`;
            pdf.text(footerText, 0.25, pgHt - 0.25);
          }

          this.isWorking = false;
        })
        .save();
    }, 1000);
  }

  downloadAttachment(attachment) {
    this.isWorking = true;
    this.http.get(attachment.locationUrl, { responseType: "blob", headers: { 'Accept': 'application/pdf' } })
      .subscribe(blob => {
        saveAs(blob, attachment.name);
        this.isWorking = false;
      });
  }

}
