import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { MatProgressBarModule } from '@angular/material';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatorComponent } from './authenticator/authenticator.component';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const routes: Routes = [
    { path: '', component: AuthenticatorComponent }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        AuthenticatorComponent
    ]
})
export class AuthenticatorModule { }
